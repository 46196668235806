'use client';

import { FC, createContext, ReactNode, useState } from 'react';
import { IPostListV1Response } from 'ui/interface/position/getList';
import { TPostList } from 'ui/component/contents/recruitment/useViewRecruitment';

interface IRecruitListInjectedContextValueProps {
  children: ReactNode;
}

interface IRecruitListInjectedContextValue {
  mutatePostListData: IPostListV1Response;
  mutatePostListPreviewData: IPostListV1Response;
  postList: TPostList;
  postListPreview: TPostList;
  setMutatePostListData: (value: IPostListV1Response) => void;
  setMutatePostListPreviewData: (value: IPostListV1Response) => void;
  setPostList: (value: TPostList) => void;
  setPostListPreview: (value: TPostList) => void;
}

const initialMutationPostListData: IPostListV1Response = {
  pagination: {
    size: 8,
    page: 1,
  },
  list: [],
};

const initialRecruitListInjectedContextValue: IRecruitListInjectedContextValue = {
  mutatePostListData: initialMutationPostListData,
  mutatePostListPreviewData: initialMutationPostListData,
  postList: () => {},
  postListPreview: () => {},
  setPostList: () => {},
  setPostListPreview: () => {},
  setMutatePostListData: () => {},
  setMutatePostListPreviewData: () => {},
};

const RecruitListInjectedContext = createContext(initialRecruitListInjectedContextValue);

export const RecruitListInjectedContextProvider: FC<IRecruitListInjectedContextValueProps> = (props) => {
  const [postList, setPostList] = useState<TPostList>(() => {});
  const [postListPreview, setPostListPreview] = useState<TPostList>(() => {});
  const [mutatePostListData, setMutatePostListData] = useState<IPostListV1Response>(initialMutationPostListData);
  const [mutatePostListPreviewData, setMutatePostListPreviewData] =
    useState<IPostListV1Response>(initialMutationPostListData);
  const { children } = props;

  return (
    <RecruitListInjectedContext.Provider
      value={{
        mutatePostListData,
        mutatePostListPreviewData,
        postList,
        postListPreview,
        setMutatePostListData,
        setMutatePostListPreviewData,
        setPostList,
        setPostListPreview,
      }}
    >
      {children}
    </RecruitListInjectedContext.Provider>
  );
};

export default RecruitListInjectedContext;
