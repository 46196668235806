import { useEffect, useState } from 'react';

interface IProps {
  fileUrl: string;
  fileType: 'IMAGE' | 'VIDEO' | null;
}

const useFileSize = ({ fileUrl, fileType }: IProps) => {
  const [img, setImg] = useState<HTMLImageElement | null>(null);
  const [video, setVideo] = useState<HTMLVideoElement | null>(null);
  const [fileSize, setFileSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (!fileType) return;
    if (fileType === 'IMAGE') {
      if (!img) {
        setImg(new Image());
        return;
      }
      img.onload = () => {
        setFileSize({ width: img.width, height: img.height });
      };
      img.src = fileUrl;
    }

    if (fileType === 'VIDEO') {
      if (!video) {
        const video = document.createElement('video');
        setVideo(video);
        return;
      }
      video.preload = 'metadata';
      video.onloadedmetadata = () => {
        setFileSize({ width: video.videoWidth, height: video.videoHeight });
      };
      video.src = fileUrl;
    }

    return () => {
      if (fileType === 'IMAGE' && img) {
        img.onload = null;
        img.remove();
      }

      if (fileType === 'VIDEO' && video) {
        video.onloadedmetadata = null;
        video.remove();
      }
    };
  }, [fileType, fileUrl, img, video]);

  return { fileSize };
};

export default useFileSize;
