import { BlockIdType } from '../block/IBlock';
import { PageIdType } from '../page/IPage';
import { ILayoutStyle } from './ILayoutStyle';
import IBanner from './banner/IBanner';

export const LAYOUT = {
  DEFAULT: 'LAYOUT',
  CONTENTS: 'CONTENTS',
  EMPTY: 'EMPTY',
  BANNER: 'BANNER',
} as const;

export type LayoutType = keyof typeof LAYOUT;

export type LayoutIdType = `layout_${string}`;

export type ILayout = {
  id: LayoutIdType;
  type: LayoutType;
  parentId: PageIdType;
  style: ILayoutStyle;
  blockIds: BlockIdType[];
  banner?: IBanner;
};
