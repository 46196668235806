import React, { FC } from 'react';
import { ViewModeType } from '../../interface/common/ViewMode';
import classnames from 'classnames/bind';
import styles from './ViewLayout.module.scss';
import IViewLayout from '../../interface/layout/IViewLayout';
import { LAYOUT } from '../../interface/layout/ILayout';
import ViewBlock from '../block/ViewBlock';
import IPageStyle from '../../interface/page/IPageStyle';
import ViewBanner from './banner/ViewBanner';
import { HasOption } from '../../interface/common/hasOption';
import { COLORS } from '../../constants/colors';
const cx = classnames.bind(styles);

interface IViewLayoutProps {
  viewMode?: ViewModeType;
  pageStyle: IPageStyle;
  viewLayout: IViewLayout;
  isCapture?: boolean;
  isPreview?: boolean;
  isEdit?: boolean;
  previewTabType?: ViewModeType;
}

const ViewLayout: FC<IViewLayoutProps & HasOption> = ({
  viewMode,
  pageStyle,
  viewLayout,
  isCapture = false,
  isPreview,
  isEdit,
  previewTabType,
  options,
}) => {
  const blockEls = viewLayout.blocks.map((viewBlock) => {
    return (
      <ViewBlock
        key={viewBlock.id}
        viewMode={viewMode}
        pageStyle={pageStyle}
        viewBlock={viewBlock}
        isCapture={isCapture}
        isPreview={isPreview}
        isEdit={isEdit}
        previewTabType={previewTabType}
        options={options}
      />
    );
  });

  const LayoutElement = (viewLayout: IViewLayout) => {
    const { type, style } = viewLayout;

    switch (type) {
      case LAYOUT.BANNER:
        return (
          <ViewBanner
            viewMode={viewMode}
            viewLayout={viewLayout}
            isCapture={isCapture}
            options={options}
            isPreview={isPreview}
            isEdit={isEdit}
          />
        );
      case LAYOUT.EMPTY:
        return null;
      default:
        return (
          <div className={cx('container')} style={{ backgroundColor: style?.backgroundColor ?? COLORS.white }}>
            {blockEls}
          </div>
        );
    }
  };

  return LayoutElement(viewLayout);
};

export default ViewLayout;
