import { IViewRecruitmentProps } from 'ui/component/contents/recruitment/useViewRecruitment';
import { useMemo } from 'react';
import { useMutationPostListV1, useQueryGetClassificationTagV1, useQueryGetSettingV1 } from '@queries/position';
import { SOLUTION_TYPE } from 'ui/util/solution';

const useRecruitmentContext = () => {
  const { data: queryGetSettingData, refetch: getSetting } = useQueryGetSettingV1(false);
  const { mutate: postList, data: mutatePostListData } = useMutationPostListV1[SOLUTION_TYPE]();

  // TODO 채용공고 태그는 채용사이트에서 현재 방식이 달라 주석처리
  // TODO 추후 editor와 폼 통일하기
  // const { data: queryGetClassificationTagData, refetch: getClassificationTag } = useQueryGetClassificationTagV1(
  //   queryGetSettingData?.tagType ?? 'USER_PROVIDED',
  //   false
  // );

  const viewRecruitmentProps: IViewRecruitmentProps = useMemo(() => {
    return {
      queryGetSettingData,
      getSetting,
      mutatePostListData,
      postList,
      // queryGetClassificationTagData,
      // getClassificationTag,
    };
  }, [
    queryGetSettingData,
    getSetting,
    mutatePostListData,
    postList,
    // queryGetClassificationTagData,
    // getClassificationTag,
  ]);

  return {
    viewRecruitmentProps,
  };
};

export default useRecruitmentContext;
