import classNames from 'classnames/bind';
import style from './RecruitSetting.module.scss';
import { ViewModeType } from '../../../interface/common/ViewMode';
import { FC, useMemo, useState } from 'react';
import Tab from '../../../common/tab/Tab';
import ITabOption from '../../../common/tab/define/ITabOption';
import {
  IJobGroupTypeList,
  IWorkingAreaList,
  TCareerType,
  TSubmissionStatusType,
} from '../../../interface/recruit/IRecruitSetting';
import KeywordFilter from './keywordFilter/keywordFilter';
import Button from '../../../common/button/Button';
import DropdownWithOnClick from '../../../common/dropdownWithOnClick/Dropdown';
import { CAREER_DEFINITION, FILTER_DEFINITION, SUBMISSION_STATUS_DEFINITION } from '../../../constants/position/common';
import { SOLUTION, SolutionType } from '../../../constants/solution/solution.constant';
import { HasOption } from '../../../interface/common/hasOption';
import { LANGUAGE, Language } from '../../../constants/language';

const cx = classNames.bind(style);

interface ISelectorList<OptionValues extends string = string> {
  tabOption: ITabOption<OptionValues>[];
  filterTypeList: string[];
  jobGroupList?: IJobGroupTypeList[];
  careerTypeList?: TCareerType[];
  workingAreaList?: IWorkingAreaList[];
  submissionStatusList?: TSubmissionStatusType[];
}

interface ISelectedList<OptionValues extends string = string> {
  tabValue: OptionValues;
  keyword: string;
  jobGroup: number[];
  careerType: TCareerType[];
  workingArea: number[];
  submissionStatus: TSubmissionStatusType[];
}

interface IRecruitSettingProps<OptionValues extends string = string> {
  solution?: SolutionType;
  viewMode?: ViewModeType;
  selectorList?: ISelectorList;
  selectedList?: ISelectedList;
  handleClickTab?: (val: OptionValues) => void;
  handleInputKeyword?: (value: string) => void;
  handleClickJobGroupSnList?: (value: number[]) => void;
  handleClickCareerTypeList?: (value: TCareerType[]) => void;
  handleClickWorkingAreaList?: (value: number[]) => void;
  handleClickSubmissionStatusList?: (value: TSubmissionStatusType[]) => void;
  readOnly?: boolean;
}

const RecruitSetting: FC<IRecruitSettingProps & HasOption> = ({
  solution = SOLUTION.JOBDA,
  viewMode = 'PC',
  selectorList = {
    tabOption: [],
    filterTypeList: [],
    jobGroupList: [],
    careerTypeList: [],
    workingAreaList: [],
    submissionStatusList: [],
  },
  selectedList = { tabValue: '', keyword: '', jobGroup: [], workingArea: [], careerType: [], submissionStatus: [] },
  handleClickTab,
  handleClickJobGroupSnList,
  handleInputKeyword,
  handleClickCareerTypeList,
  handleClickWorkingAreaList,
  handleClickSubmissionStatusList,
  readOnly = false,
  options,
}) => {
  const {
    tabOption = [],
    filterTypeList,
    jobGroupList = [],
    careerTypeList = [],
    workingAreaList = [],
    submissionStatusList = [],
  } = selectorList;
  const { tabValue, keyword, jobGroup, workingArea, careerType, submissionStatus } = selectedList;
  /**
   *   keywordInput: 자음이나 모음만 있을 땐 검색이 안되도록 하기위해 view를 위한 데이터
   *   keyword: 실제 검색에 사용되는 데이터
   */
  const [keywordInput, setKeywordInput] = useState('');
  const languageType = (options && (options['languageType'] as Language)) || LANGUAGE.KOR;

  const handleInputKeywordInput = (text: string) => {
    setKeywordInput(text);
  };

  const handleClickReset = () => {
    handleInputKeywordInput('');
    if (handleInputKeyword) handleInputKeyword('');
    if (handleClickJobGroupSnList) handleClickJobGroupSnList([]);
    if (handleClickCareerTypeList) handleClickCareerTypeList([]);
    if (handleClickWorkingAreaList) handleClickWorkingAreaList([]);
    if (handleClickSubmissionStatusList) handleClickSubmissionStatusList([]);
    if (handleClickTab) handleClickTab('');
  };

  const tabSwipeOption = useMemo(() => {
    return { enabled: viewMode === 'MOBILE' };
  }, [viewMode]);

  const resetText = {
    KOR: '초기화',
    ENG: 'Reset',
  };

  return (
    <>
      {tabOption.length > 1 && (
        <div className={cx('tags-wrapper', viewMode)}>
          <Tab
            options={tabOption}
            value={tabValue}
            className={cx('tab')}
            onChange={handleClickTab}
            swipeOption={tabSwipeOption}
          />
        </div>
      )}
      <div className={cx('filter-list', { full: filterTypeList.length === 4 }, viewMode)}>
        <KeywordFilter
          className={cx('keyword-filter')}
          solution={solution}
          viewMode={viewMode}
          keyword={keyword}
          keywordInput={keywordInput}
          handleInputKeyword={handleInputKeyword}
          handleInputKeywordInput={handleInputKeywordInput}
          disabled={readOnly}
          options={options}
        />
        {filterTypeList.includes('JOB_GROUP_AND_JOB') && (
          <div className={cx('filter')} key={'JOB_GROUP_AND_JOB'}>
            <DropdownWithOnClick.Multiple
              placeholder={FILTER_DEFINITION['JOB_GROUP_AND_JOB'][languageType]}
              options={jobGroupList.map((value) => {
                return {
                  value: value.sn,
                  name: languageType === LANGUAGE.KOR ? value.name : value.englishName ?? value.name,
                };
              })}
              size={'lg'}
              className={cx('dropdown')}
              variant={'check'}
              onChange={handleClickJobGroupSnList}
              value={jobGroup}
              disabled={readOnly}
              selectedTextFormat={(count) => {
                const selectedList = jobGroupList.filter(({ sn }) => jobGroup.includes(sn));
                if (selectedList.length === 0) return FILTER_DEFINITION['JOB_GROUP_AND_JOB'][languageType];
                if (selectedList.length === 1) {
                  switch (languageType) {
                    case LANGUAGE.KOR:
                      return selectedList[0].name;
                    case LANGUAGE.ENG:
                      return selectedList[0].englishName ?? selectedList[0].name;
                  }
                }
                switch (languageType) {
                  case LANGUAGE.KOR:
                    return `${selectedList[0].name} 외 ${count - 1}개 선택`;
                  case LANGUAGE.ENG:
                    return `${selectedList[0].englishName ?? selectedList[0].name} and ${count - 1} more`;
                }
              }}
            />
          </div>
        )}
        {filterTypeList.includes('CAREER_TYPE') && (
          <div className={cx('filter')} key={'CAREER_TYPE'}>
            <DropdownWithOnClick.Multiple
              placeholder={FILTER_DEFINITION['CAREER_TYPE'][languageType]}
              options={careerTypeList.map((value) => {
                return {
                  value: value,
                  name: CAREER_DEFINITION[value][languageType],
                };
              })}
              size={'lg'}
              className={cx('dropdown')}
              variant={'check'}
              onChange={handleClickCareerTypeList}
              value={careerType}
              disabled={readOnly}
              selectedTextFormat={(count) => {
                const selectedList = careerTypeList.filter((value) => careerType.includes(value));
                if (selectedList.length === 0) return FILTER_DEFINITION['CAREER_TYPE'][languageType];
                if (selectedList.length === 1) return CAREER_DEFINITION[selectedList[0]][languageType];
                switch (languageType) {
                  case LANGUAGE.KOR:
                    return `${CAREER_DEFINITION[selectedList[0]][LANGUAGE.KOR]} 외 ${count - 1}개 선택`;
                  case LANGUAGE.ENG:
                    return `${CAREER_DEFINITION[selectedList[0]][LANGUAGE.ENG]} and ${count - 1} more`;
                }
              }}
            />
          </div>
        )}
        {filterTypeList.includes('WORKING_AREA') && (
          <div className={cx('filter')} key={'WORKING_AREA'}>
            <DropdownWithOnClick.Multiple
              placeholder={FILTER_DEFINITION['WORKING_AREA'][languageType]}
              options={workingAreaList.map((value) => {
                return {
                  value: value.sn,
                  name: languageType === LANGUAGE.KOR ? value.name : value.englishName ?? value.name,
                };
              })}
              size={'lg'}
              className={cx('dropdown')}
              variant={'check'}
              onChange={handleClickWorkingAreaList}
              value={workingArea}
              disabled={readOnly}
              selectedTextFormat={(count) => {
                const selectedList = workingAreaList.filter(({ sn }) => workingArea.includes(sn));
                if (selectedList.length === 0) return FILTER_DEFINITION['WORKING_AREA'][languageType];
                if (selectedList.length === 1) return selectedList[0].name;
                switch (languageType) {
                  case LANGUAGE.KOR:
                    return `${selectedList[0].name} 외 ${count - 1}개 선택`;
                  case LANGUAGE.ENG:
                    return `${selectedList[0].englishName ?? selectedList[0].name} and ${count - 1} more`;
                }
              }}
            />
          </div>
        )}
        {filterTypeList.includes('SUBMISSION_STATUS') && (
          <div className={cx('filter')} key={'SUBMISSION_STATUS'}>
            <DropdownWithOnClick.Multiple
              placeholder={FILTER_DEFINITION['SUBMISSION_STATUS'][languageType]}
              options={submissionStatusList.map((value) => {
                return {
                  value: value,
                  name: SUBMISSION_STATUS_DEFINITION[value][languageType],
                };
              })}
              size={'lg'}
              className={cx('dropdown')}
              variant={'check'}
              onChange={handleClickSubmissionStatusList}
              value={submissionStatus}
              disabled={readOnly}
              selectedTextFormat={(count) => {
                const selectedList = submissionStatusList.filter((value) => submissionStatus.includes(value));
                if (selectedList.length === 0) return FILTER_DEFINITION['SUBMISSION_STATUS'][languageType];
                if (selectedList.length === 1) return SUBMISSION_STATUS_DEFINITION[selectedList[0]][languageType];
                switch (languageType) {
                  case LANGUAGE.KOR:
                    return `${SUBMISSION_STATUS_DEFINITION[selectedList[0]][LANGUAGE.KOR]} 외 ${count - 1}개 선택`;
                  case LANGUAGE.ENG:
                    return `${SUBMISSION_STATUS_DEFINITION[selectedList[0]][LANGUAGE.ENG]} and ${count - 1} more`;
                }
              }}
            />
          </div>
        )}
        <Button
          leftIcon={'reset_line'}
          variant={'text'}
          size={'sm'}
          className={cx('reset', viewMode)}
          onClick={handleClickReset}
        >
          {resetText[languageType]}
        </Button>
      </div>
    </>
  );
};

export default RecruitSetting;
