import { useEffect } from 'react';
import { ReadonlyURLSearchParams, usePathname, useSearchParams } from 'next/navigation';

/** 페이지 마운트시 스크롤 맨 위로 올리는 커스텀 훅 */
const useScrollPositionTop = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams() as ReadonlyURLSearchParams;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, searchParams]);
};

export default useScrollPositionTop;
