import IBannerStyle from './IBannerStyle';
import { Descendant } from 'slate';
import IBannerValue from './IBannerValue';
import { LinkStyle } from 'ui/interface/common/Link';

export const BANNER_OPTION = {
  IMAGE: 'IMAGE',
  COLOR: 'COLOR',
} as const;

export const BANNER_MEDIA = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
} as const;

export type BannerMediaType = keyof typeof BANNER_MEDIA;
export type BannerOptionType = keyof typeof BANNER_OPTION;

interface IBanner {
  type: BannerOptionType; // 이미지 색상 탭
  text: Descendant[] | string;
  style: IBannerStyle;
  value: IBannerValue;
  link?: LinkStyle;
}

export default IBanner;
