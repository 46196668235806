import { useContext, useEffect } from 'react';
import RecruitSettingInjectedContext from 'ui/context/recruiter/RecruitSettingInjectedContext';
import { IViewRecruitmentProps } from 'ui/component/contents/recruitment/useViewRecruitment';
import RecruitListInjectedContext from '../../../context/recruiter/RecruitListInjectedContext';

export interface IViewPageProps {
  viewRecruitmentProps: IViewRecruitmentProps;
}

const useRecruitInjectedContext = (viewRecruitmentProps?: IViewRecruitmentProps) => {
  const { setPostList, setMutatePostListData, setPostListPreview, setMutatePostListPreviewData } =
    useContext(RecruitListInjectedContext);
  const { setGetSetting, setGetClassificationTag, setQueryGetSettingData, setQueryGetClassificationTagData } =
    useContext(RecruitSettingInjectedContext);

  const {
    getSetting: _getSetting,
    getClassificationTag: _getClassificationTag,
    queryGetSettingData: _queryGetSettingData,
    queryGetClassificationTagData: _queryGetClassificationTagData,
    postList: _postList,
    mutatePostListData: _mutatePostListData,
    postListPreview: _postListPreview,
    mutatePostListPreviewData: _mutatePostListPreviewData,
  } = viewRecruitmentProps || {};

  useEffect(() => {
    setGetSetting(() => _getSetting);
  }, [_getSetting, setGetSetting]);

  useEffect(() => {
    setGetClassificationTag(() => _getClassificationTag);
  }, [_getClassificationTag, setGetClassificationTag]);

  useEffect(() => {
    if (!_queryGetSettingData) return;
    setQueryGetSettingData(_queryGetSettingData);
  }, [_queryGetSettingData, setQueryGetSettingData]);

  useEffect(() => {
    if (!_queryGetClassificationTagData) return;
    setQueryGetClassificationTagData(_queryGetClassificationTagData);
  }, [_queryGetClassificationTagData, setQueryGetClassificationTagData]);

  useEffect(() => {
    setPostList(() => _postList);
  }, [_postList, setPostList]);

  useEffect(() => {
    if (!_mutatePostListData) return;
    setMutatePostListData(_mutatePostListData);
  }, [_mutatePostListData, setMutatePostListData]);

  useEffect(() => {
    setPostListPreview(() => _postListPreview);
  }, [_postListPreview, setPostListPreview]);

  useEffect(() => {
    if (!_mutatePostListPreviewData) return;
    setMutatePostListPreviewData(_mutatePostListPreviewData);
  }, [_mutatePostListPreviewData, setMutatePostListPreviewData]);
};

export default useRecruitInjectedContext;
