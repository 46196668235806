import IViewContents from '../../../interface/contents/IViewContents';
import { IVideo } from '../../../interface/contents/video/IVideo';

import { useMemo } from 'react';
import { VIDEO_RATIO } from '../../../constants/editor/video.constant';
import { EDITOR_GROUP_WIDTH_PER_COLUMN } from '../../../constants/editor/block.constant';
import { GroupIdType } from '../../../interface/group/IGroup';
import useViewBlockGroupWidth from '../../../hook/editor/useViewBlockGroupWidth';

interface IProps {
  blockColumn?: number;
  refGroupId?: GroupIdType;
  viewVideo: IViewContents<IVideo>;
  wrapperRef: React.RefObject<HTMLDivElement>;
}

const useViewVideoData = ({ blockColumn, refGroupId, viewVideo, wrapperRef }: IProps) => {
  const { style } = viewVideo;
  const {
    sizeType,
    sizePercentage,
    borderRadius: { leftDownRadius, leftTopRadius, rightDownRadius, rightTopRadius },
  } = style;

  const { refGroupWidth } = useViewBlockGroupWidth({ refGroupId });

  const VIDEO_MINIMUM_WIDTH = 68;
  const _videoCalculatedWidth = useMemo(() => {
    if (blockColumn) {
      const editorVideoPercentageWidth = Math.round(
        (EDITOR_GROUP_WIDTH_PER_COLUMN(blockColumn) * sizePercentage) / 100
      );
      return Math.max(VIDEO_MINIMUM_WIDTH, editorVideoPercentageWidth);
    }

    const wrapperWidth = wrapperRef.current?.clientWidth ?? 9999;
    const calculatedPercentageWidth = Math.min(
      refGroupWidth ? refGroupWidth : wrapperWidth,
      Math.round((sizePercentage * wrapperWidth) / 100)
    );
    return Math.max(calculatedPercentageWidth, VIDEO_MINIMUM_WIDTH);
  }, [blockColumn, sizeType, sizePercentage, wrapperRef?.current?.clientWidth, refGroupWidth]);

  const size = useMemo(
    () => ({
      width: _videoCalculatedWidth,
      height: _videoCalculatedWidth * VIDEO_RATIO,
    }),
    [_videoCalculatedWidth]
  );

  const videoCalculatedStyle = useMemo(() => {
    return {
      width: `${_videoCalculatedWidth}px`,
      height: `${_videoCalculatedWidth * VIDEO_RATIO}px`,
      borderRadius: `${leftTopRadius}px ${rightTopRadius}px ${rightDownRadius}px ${leftDownRadius}px`,
    };
  }, [_videoCalculatedWidth, leftTopRadius, rightTopRadius, rightDownRadius, leftDownRadius]);

  return { size, videoCalculatedStyle };
};

export default useViewVideoData;
