import React, { FC } from 'react';
import classnames from 'classnames/bind';
import styles from './ViewPage.module.scss';
import { ViewModeType } from '../../interface/common/ViewMode';
import IViewPage from '../../interface/page/IViewPage';
import ViewLayout from '../layout/ViewLayout';
import { DEFAULT_PAGE_STYLE } from 'ats-editor/src/constant/DefaultEditor';
import TopButton from './TopButton';
import useViewPage, { IViewPageProps } from './useViewPage';
import ContentsProvider from '../../context/ContentsContext';
import { HasOption } from '../../interface/common/hasOption';
import { isOnOff } from 'ui/constants/common';
import { DEFAULT_LAYOUT_MARGIN_BOTTOM } from '../../constants/editor/page.constant';

const cx = classnames.bind(styles);

/**
 *  Note : isCapture true 시 인터렉션 전부 제거되어야함, 화면캡처시 fadeOut 상태이면 빈영역으로 캡처됨
 * */

interface IProps {
  viewMode?: ViewModeType;
  viewPage?: IViewPage;
  viewPageProps?: IViewPageProps;
  isCapture?: boolean; // TODO context 전역 상태화
  isPreview?: boolean;
  isEdit?: boolean;
  previewTabType?: ViewModeType;
}

const ViewPageInner: FC<IProps & HasOption> = ({
  viewPage,
  viewPageProps,
  viewMode,
  isCapture = false,
  isPreview = false,
  isEdit = false,
  previewTabType,
  options,
}) => {
  useViewPage(viewPageProps);
  if (!viewPage) return null;
  const { style } = viewPage;

  const gap = isOnOff(style?.isToggledGap)
    ? `${style.layoutMarginBottom ?? DEFAULT_LAYOUT_MARGIN_BOTTOM}px`
    : `${DEFAULT_PAGE_STYLE.layoutMarginBottom}px`;

  const layoutEls = viewPage.layouts.map((viewLayout) => {
    return (
      <ViewLayout
        key={viewLayout.id}
        viewMode={viewMode}
        pageStyle={viewPage.style}
        viewLayout={viewLayout}
        isCapture={isCapture}
        isPreview={isPreview}
        isEdit={isEdit}
        previewTabType={previewTabType}
        options={options}
      />
    );
  });

  const channelTalkActiveStatus = options?.channelTalkActiveStatus ?? false;

  return (
    <>
      <div className={cx('container', viewMode)} style={{ gap }}>
        {layoutEls}
      </div>
      <TopButton needMovePosition={channelTalkActiveStatus} />
    </>
  );
};

const ViewPage: FC<IProps & HasOption> = (props) => {
  return (
    <ContentsProvider>
      <ViewPageInner {...props} />
    </ContentsProvider>
  );
};

export default ViewPage;
