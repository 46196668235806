import { ILayout, LAYOUT, LayoutIdType } from 'ui/interface/layout/ILayout';
import IBlock, { Block, BlockIdType } from 'ui/interface/block/IBlock';
import { IContents, CONTENTS, ContentsIdType } from 'ui/interface/contents/IContents';
import IPage, { PageIdType } from 'ui/interface/page/IPage';
import IGroup, { GroupIdType } from 'ui/interface/group/IGroup';
import { v1 } from 'uuid';
import IPageStyle from 'ui/interface/page/IPageStyle';
import { BlockPaddingMap, PaddingType, EnumPadding } from 'ui/interface/common/Padding';
import { COLORS } from 'ui/constants/colors';
import { transparentize } from 'ui/util/color.utils';
import {
  DEFAULT_BLOCK_GAP,
  DEFAULT_CONTENTS_GROUP_GAP,
  DEFAULT_CONTENTS_GAP,
  DEFAULT_LAYOUT_MARGIN_BOTTOM,
} from 'ui/constants/editor/page.constant';

export const ids = {
  page_0_id: `page_${v1()}` as PageIdType,
  layout_0_id: `layout_${v1()}` as LayoutIdType,
  layout_1_id: `layout_${v1()}` as LayoutIdType,
  block_0_id: `block_${v1()}` as BlockIdType,
  group_0_id: `group_${v1()}` as GroupIdType,
  contents_0_id: `contents_${v1()}` as ContentsIdType,
};

export const DEFAULT_PAGE_STYLE: IPageStyle = {
  isToggledGap: 'OFF',
  blockGap: DEFAULT_BLOCK_GAP,
  contentsGroupGap: DEFAULT_CONTENTS_GROUP_GAP,
  contentsGap: DEFAULT_CONTENTS_GAP,
  layoutMarginBottom: DEFAULT_LAYOUT_MARGIN_BOTTOM,
};

export const getDefaultPage = (id: PageIdType, layoutId: LayoutIdType, emptyLayoutId: LayoutIdType) => {
  return {
    id: id,
    layoutIds: [layoutId, emptyLayoutId],
    style: DEFAULT_PAGE_STYLE,
  } as IPage;
};

export const defaultLayoutStyle = {
  backgroundColor: COLORS.white,
};

export const getDefaultLayout = (pageId: PageIdType, id: LayoutIdType, blockId: BlockIdType) => {
  return {
    id: id,
    parentId: pageId,
    type: LAYOUT.CONTENTS,
    blockIds: [blockId],
    style: defaultLayoutStyle,
  } as ILayout;
};

export const getDefaultEmptyLayout = (pageId: PageIdType, id: LayoutIdType) => {
  return {
    id: id,
    parentId: pageId,
    type: LAYOUT.EMPTY,
    blockIds: [],
    style: {
      backgroundColor: transparentize(COLORS.white),
    },
  } as ILayout;
};

export const getDefaultBlock = (layoutId: LayoutIdType, id: BlockIdType, groupId: GroupIdType) => {
  return {
    id: id,
    type: Block.DEPTH1,
    parentId: layoutId,
    pcSortOrder: '0',
    mobileSortOrder: '0',
    style: {
      backgroundColor: transparentize(COLORS.white),
      padding: BlockPaddingMap[EnumPadding.MEDIUM as PaddingType],
    },
    groupIds: [groupId],
  } as IBlock;
};

export const getDefaultGroup = (
  blockId: BlockIdType,
  id: GroupIdType,
  contentsId: ContentsIdType,
  option: { columnNumber: number; pcSortOrder: number; mobileSortOrder: number } = {
    columnNumber: 0,
    pcSortOrder: 0,
    mobileSortOrder: 0,
  }
) => {
  return {
    id: id,
    parentId: blockId,
    contentsIds: [contentsId],
    ...option,
  } as IGroup;
};

export const getDefaultContents = (groupId: GroupIdType, id: ContentsIdType) => {
  return {
    id: id,
    parentId: groupId,
    type: CONTENTS.EMPTY,
    value: undefined,
    style: undefined,
  } as IContents<any>;
};

export interface IEditor {
  page: {
    allIds: PageIdType[];
    byIds: {
      [id: PageIdType]: IPage;
    };
  };
  layout: {
    allIds: LayoutIdType[];
    byIds: {
      [id: LayoutIdType]: ILayout;
    };
  };
  block: {
    allIds: BlockIdType[];
    byIds: {
      [id: BlockIdType]: IBlock;
    };
  };
  group: {
    allIds: GroupIdType[];
    byIds: {
      [id: GroupIdType]: IGroup;
    };
  };
  contents: {
    allIds: ContentsIdType[];
    byIds: {
      [id: ContentsIdType]: IContents<any>; // FIXME any 타입 수정
    };
  };
}

export const DefaultEditor: IEditor = {
  page: {
    allIds: [ids.page_0_id],
    byIds: {
      [ids.page_0_id]: getDefaultPage(ids.page_0_id, ids.layout_0_id, ids.layout_1_id),
    },
  },
  layout: {
    allIds: [ids.layout_0_id, ids.layout_1_id],
    byIds: {
      [ids.layout_0_id]: getDefaultLayout(ids.page_0_id, ids.layout_0_id, ids.block_0_id),
      [ids.layout_1_id]: getDefaultEmptyLayout(ids.page_0_id, ids.layout_1_id),
    },
  },
  block: {
    allIds: [ids.block_0_id],
    byIds: {
      [ids.block_0_id]: getDefaultBlock(ids.layout_0_id, ids.block_0_id, ids.group_0_id),
    },
  },
  group: {
    allIds: [ids.group_0_id],
    byIds: {
      [ids.group_0_id]: getDefaultGroup(ids.block_0_id, ids.group_0_id, ids.contents_0_id),
    },
  },
  contents: {
    allIds: [ids.contents_0_id],
    byIds: {
      [ids.contents_0_id]: getDefaultContents(ids.group_0_id, ids.contents_0_id),
    },
  },
};
