import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { AccordionTabIdType, IAccordionValue } from '../../../interface/contents/accordion/IAccordion';
import { OFF } from '../../../constants/common';
import { getAccordionBody, getAccordionTitle } from '../../../util/editor/accordionContent.utils';

export const ACCORDION_TAB_ALL = `accordion_tab_all`;

export const useViewAccordion = (
  accordionValue: IAccordionValue,
  keyword: string,
  currentTabId: AccordionTabIdType,
  setAccordionItemOpenStatusMap: Dispatch<SetStateAction<{}>>
) => {
  const { accordionList, accordionTabList, tabStatusType } = accordionValue;

  const accordionListTabFilterView = useMemo(() => {
    if (tabStatusType === OFF) return accordionList;

    if (currentTabId === ACCORDION_TAB_ALL) return accordionList;
    const selectedTab = accordionTabList.find((tabItem) => tabItem.id === currentTabId);
    if (!selectedTab) return [];
    return selectedTab.accordionIdList.map((accordionId) =>
      accordionList.find((accordionItem) => accordionItem.id === accordionId)
    );
  }, [accordionList, accordionTabList, tabStatusType, currentTabId]);

  const accordionListSearchFilterView = useMemo(() => {
    if (!keyword.length) return accordionListTabFilterView;
    if (!accordionListTabFilterView) return [];

    return accordionListTabFilterView.filter((accordionItem) => {
      if (!accordionItem) return false;

      if (getAccordionBody(accordionItem).includes(keyword)) {
        // TODO: 검색시 UNFOLD
        // setAccordionItemOpenStatusMap((prev) => ({ ...prev, [accordionItem.id]: true }));
        return true;
      }
      if (getAccordionTitle(accordionItem).includes(keyword)) return true;
      return false;
    });
  }, [keyword, accordionListTabFilterView]);

  return {
    accordionListSearchFilterView,
    currentTabId,
    accordionTabList,
    tabStatusType,
    setAccordionItemOpenStatusMap,
  };
};
