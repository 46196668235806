import { FC, useMemo } from 'react';
import classnames from 'classnames/bind';
import styles from './ViewBoardInner.module.scss';
import { VIEW_MODE, ViewModeType } from '../../../interface/common/ViewMode';
import IViewContents from '../../../interface/contents/IViewContents';
import { BOARD_VIEW_TYPE, IBoard, TBoardViewType } from '../../../interface/contents/board/IBoard';
import { IThumbnailPost } from '../../../interface/contents/board/IPostList';
import ViewBoardCategory from './ViewBoardCategory';
import ViewBoardImage from './ViewBoardImage';
import Icon from '../../../common/icon/Icon';
import { COLORS } from '../../../constants/colors';
import { useRouter } from 'next/navigation';
import { SOLUTION_TYPE } from '../../../util/solution';
import { SOLUTION } from '../../../constants/solution/solution.constant';
import Divider from '../../common/Divider';
import { OFF } from '../../../constants/common';
import useWarningToast from '../../../context/toast/useWarningToast';
import { HasOption } from '../../../interface/common/hasOption';
import { LANGUAGE } from '../../../constants/language';
import { useUrl } from '../../../hook/useUrl';

const cx = classnames.bind(styles);

interface IProps {
  viewType: TBoardViewType;
  viewMode?: ViewModeType;
  articleList: IThumbnailPost[];
  viewBoard: IViewContents<IBoard>;
  isPreview?: boolean;
  isEdit?: boolean;
  previewTabType?: ViewModeType;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const ViewBoardInner: FC<IProps & HasOption> = ({
  viewType,
  viewMode = VIEW_MODE.PC,
  articleList,
  viewBoard,
  isPreview,
  isEdit,
  previewTabType,
  containerRef,
  options,
}) => {
  const router = useRouter();
  const { setMobilePreviewDisabledWarningToast } = useWarningToast();
  const languageType = options && options['languageType'];
  const { buildUrl } = useUrl();

  const isNoPostDate = useMemo(() => {
    return viewBoard.style.postDateStatus === OFF;
  }, [viewBoard.style.postDateStatus]);

  const handleClickArticle = (articleSn: number) => {
    if (isPreview) {
      if (isEdit) {
        setMobilePreviewDisabledWarningToast();
      } else {
        router.push(`${window.location.href}&articleSn=${articleSn}&previewType=post`);
      }
      return;
    }
    if (isEdit) return;
    router.push(
      buildUrl(`/${SOLUTION_TYPE === SOLUTION.JOBFLEX ? 'career/' : ''}article/${articleSn}`)
        .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
        .getResult()
    );
  };

  return (
    <div className={cx('list-container', viewType, viewMode)}>
      {articleList.map((article) => {
        const { articleSn, title, thumbnailTitle, startDate } = article;
        return (
          <>
            <div key={articleSn} className={cx('article')} onClick={() => handleClickArticle(articleSn)}>
              <ViewBoardImage
                imageFile={article.thumbnailImage}
                viewType={viewType}
                viewBoard={viewBoard}
                viewMode={viewMode}
                previewTabType={previewTabType}
                containerRef={containerRef}
              />
              <div className={cx('article-contents-container')}>
                <div className={cx('article-contents')}>
                  <ViewBoardCategory
                    categoryHideStatus={viewBoard?.style.categoryHideStatus}
                    viewMode={viewMode}
                    categoryName={article.categoryName}
                  />
                  <h5 className={cx('article-title', viewMode)}>{title}</h5>
                  <p className={cx('article-description', viewMode)}>{thumbnailTitle}</p>
                </div>
                {!isNoPostDate && (
                  <div className={cx('start-time', viewMode)}>
                    <Icon name={'time_line'} size={18} color={COLORS.gray600} />
                    <p>{startDate?.replaceAll('-', '.')}</p>
                  </div>
                )}
              </div>
            </div>
            {viewType === BOARD_VIEW_TYPE.LIST && (
              <div className={cx('divider')}>
                <Divider margin={viewMode === VIEW_MODE.MOBILE ? 0 : 8} />
              </div>
            )}
            {viewType === BOARD_VIEW_TYPE.KANBAN && viewMode === VIEW_MODE.MOBILE && (
              <div className={cx('divider')}>
                <Divider margin={0} />
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};

export default ViewBoardInner;
