import IViewLayout from '../../../interface/layout/IViewLayout';
import { BANNER_OPTION } from '../../../interface/layout/banner/IBanner';
import { FC } from 'react';
import classnames from 'classnames/bind';
import styles from './ViewBanner.module.scss';
import { ViewModeType } from '../../../interface/common/ViewMode';
import ViewBannerColor from './ViewBannerColor';
import ViewBannerImage from './ViewBannerImage';
import useIntersectionObserver from '../../../hook/useIntersectionObserver';
import useScrollToBottom from '../../../hook/useScrollToBottom';
import { HasOption, PageList } from '../../../interface/common/hasOption';
import useClickLinkedElement from '../../../hook/link/useClickLinkedElement';
import { ON } from '../../../constants/common';
import { defaultLayoutStyle } from 'ats-editor/src/constant/DefaultEditor';
const cx = classnames.bind(styles);

interface IViewBannerProps {
  viewMode?: ViewModeType;
  viewLayout: IViewLayout;
  isCapture?: boolean;
  isPreview?: boolean;
  isEdit?: boolean;
}

const ViewBanner: FC<IViewBannerProps & HasOption> = ({
  viewMode,
  viewLayout,
  isCapture,
  isPreview,
  isEdit,
  options,
}) => {
  const pageLinkList = options?.pageLinkList as PageList;
  const languageType = options && options['languageType'];
  const { handleClickLinkedElement } = useClickLinkedElement(pageLinkList);
  const { backgroundColor } = viewLayout.style ?? defaultLayoutStyle;
  const { type } = viewLayout.banner!;

  // 스크롤 hook
  const { isIntersection, setIsIntersection } = useIntersectionObserver({
    idList: [viewLayout.id],
  });

  // 바닥 감지 hook
  useScrollToBottom({
    onBottom: () => setIsIntersection(true),
  });

  const handleClick = () => {
    handleClickLinkedElement(viewLayout.banner?.link, isPreview, isEdit, languageType);
  };
  const hasLink = viewLayout.banner?.link?.linkStatusType === ON;

  const getImgEl = () => {
    switch (type) {
      case BANNER_OPTION.COLOR:
        return (
          <ViewBannerColor
            viewMode={viewMode}
            viewLayout={viewLayout}
            isPreview={isPreview}
            options={options}
            onClick={hasLink ? handleClick : undefined}
          />
        );
      case BANNER_OPTION.IMAGE:
        return (
          <ViewBannerImage
            viewMode={viewMode}
            viewLayout={viewLayout}
            options={options}
            isPreview={isPreview}
            isCapture={isCapture}
            onClick={hasLink ? handleClick : undefined}
          />
        );
    }
  };

  return (
    <div id={viewLayout.id} className={cx('container')} style={{ backgroundColor }}>
      <div className={cx('bannerWrap', { fadeIn: !isCapture && isIntersection }, { isCapture })}>{getImgEl()}</div>
    </div>
  );
};

export default ViewBanner;
