import { POPUP_TYPE } from 'ui/constants/popup/popup.constant';
import PopupImage from 'ui/component/popup/PopupImage';
import PopupText from 'ui/component/popup/PopupText';
import style from './PopupList.module.scss';
import classNames from 'classnames/bind';
import { IGetPopupListV1Response, IPopupMutate, IPopupWithSn } from 'ui/interface/popup/popup.interface';
import { UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { PopupCardMobile } from './PopupListMobile';
import { PopupListPc } from './PopupListPc';
import { HasOption } from '../../interface/common/hasOption';

export const cx = classNames.bind(style);
export const blockPopupTemplate = (popupSn: string) => `block-popup-${popupSn}`;

export type PopupListPropType = UseQueryResult<IGetPopupListV1Response, AxiosError>;
interface IPopupList {
  isMobile?: boolean;
  popupList: PopupListPropType;
}

const PopupList = (props: IPopupList & HasOption) => {
  const { popupList, isMobile = false, options } = props;

  if (isMobile) return <PopupCardMobile {...popupList} options={options} />;
  return <PopupListPc {...popupList} options={options} />;
};

export const popupBlockFilter = ({ popUpSn }: IPopupWithSn, i: number) =>
  localStorage.getItem(blockPopupTemplate(popUpSn)) !== new Date().toLocaleDateString();

export const getPopupMain = (popup: IPopupMutate, isMobile: boolean = false, options: HasOption['options']) =>
  popup.popUpType === POPUP_TYPE.TEXT ? (
    <PopupText isMobile={isMobile} {...popup.popUpText} options={options} />
  ) : (
    <PopupImage isMobile={isMobile} {...popup.popUpImage} />
  );

export default PopupList;
