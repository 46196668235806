import { FC, PropsWithChildren, createContext, useState } from 'react';
import { SOLUTION, SolutionType } from 'ui/constants/solution/solution.constant';
import { SOLUTION_TYPE } from '../util/solution';

type ISolutionContextValue = {
  solution: SolutionType;
  setSolution: (value: SolutionType) => void;
};

export const initialSolutionContextValue: ISolutionContextValue = {
  solution: SOLUTION_TYPE,
  setSolution: () => {},
};

export const SolutionContext = createContext(initialSolutionContextValue);

export const SolutionContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [solution, setSolution] = useState<SolutionType>(SOLUTION_TYPE);

  return <SolutionContext.Provider value={{ solution, setSolution }}>{children}</SolutionContext.Provider>;
};

export default SolutionContext;
