import { useContext, useEffect } from 'react';
import BoardInjectedContext from '../../../context/board/BoardInjectedContext';
import { IViewBoardProps } from '../../contents/board/useViewBoard';

export interface IViewPageProps {
  viewBoardProps: IViewBoardProps;
}

const useBoardInjectedContext = (viewBoardProps?: IViewBoardProps) => {
  const { setMutatePostThumbnailPostListData, setPostThumbnailPostList } = useContext(BoardInjectedContext);

  const {
    postThumbnailPostList: _postThumbnailPostList,
    mutatePostThumbnailPostListData: _mutatePostThumbnailPostListData,
  } = viewBoardProps || {};

  useEffect(() => {
    setPostThumbnailPostList(() => _postThumbnailPostList);
  }, [_postThumbnailPostList, setPostThumbnailPostList]);

  useEffect(() => {
    if (!_mutatePostThumbnailPostListData) return;
    setMutatePostThumbnailPostListData(_mutatePostThumbnailPostListData);
  }, [_mutatePostThumbnailPostListData, setMutatePostThumbnailPostListData]);
};

export default useBoardInjectedContext;
