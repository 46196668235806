import { useState, useEffect } from 'react';
import { Nullable } from '../../interface/common/common';
import { GroupIdType } from '../../interface/group/IGroup';

interface IProps {
  refGroupId?: GroupIdType;
}

const useViewBlockGroupWidth = ({ refGroupId }: IProps) => {
  const [refGroup, setRefGroup] = useState<Nullable<Element>>(null);
  const [refGroupWidth, setRefGroupWidth] = useState(0);

  const resizeObserver = new ResizeObserver((entries) => {
    const { width } = entries[0].contentRect;
    setRefGroupWidth(width);
  });

  useEffect(() => {
    const currentRefGroup = document.querySelector(`.group-width-${refGroupId}`);
    setRefGroup(currentRefGroup);
  }, [refGroupId]);

  useEffect(() => {
    if (!refGroup) return;
    resizeObserver.observe(refGroup);
    return () => resizeObserver.unobserve(refGroup);
  }, [refGroup]);

  return { refGroupWidth };
};

export default useViewBlockGroupWidth;
