// 선명도 -> ??%;
export const clarify = (color: string, clarity = 100) => {
  if (clarity < 0 || clarity > 100) throw new Error('Clarity percentage should be between 0 and 100.');

  const clarityAlpha = Math.round((clarity / 100) * 255)
    .toString(16)
    .padStart(2, '0');
  return color.slice(0, 7) + clarityAlpha;
};

// 선명도 -> 0%;
export const transparentize = (color: string) => {
  return clarify(color, 0);
};

export const isTransparent = (color: string) => {
  return color.slice(7, 9) === '00';
};
