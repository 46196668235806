import { VIEW_MODE, ViewModeType } from '../../../interface/common/ViewMode';
import classnames from 'classnames/bind';
import styles from './ViewBoardCategory.module.scss';
import { useContext, useMemo } from 'react';
import BoardSettingContext from '../../../context/board/BoardSettingContext';
import Tag from '../../../common/tag/Tag';
import { OnOffType } from '../../../constants/common';
import useSearchParamsExtended from '../../../hook/useSearchParamsExtended';

const cx = classnames.bind(styles);

export interface IProps {
  viewMode: ViewModeType;
  categoryHideStatus: OnOffType;
  categoryName: string;
}

const ViewBoardCategory = ({ viewMode, categoryHideStatus, categoryName }: IProps) => {
  const { selectedCategory } = useContext(BoardSettingContext);
  const { set } = useSearchParamsExtended();
  const handleClickCategory = (categoryName: string) => {
    const newSelectedCategory = selectedCategory === categoryName ? '' : categoryName;
    set('articleSelectedCategory', newSelectedCategory);
    set('articlePage', 1);
  };

  const showCategory = useMemo(() => {
    return categoryHideStatus === 'OFF';
  }, [categoryHideStatus]);

  return (
    <>
      {showCategory && categoryName && (
        <Tag
          className={cx('article-category')}
          onClick={(e) => {
            e.stopPropagation();
            handleClickCategory(categoryName);
          }}
          variant={categoryName === selectedCategory ? 'primary' : 'outlined'}
          color={'dark'}
          size={viewMode === VIEW_MODE.MOBILE ? 'sm' : 'lg'}
        >
          {categoryName}
        </Tag>
      )}
    </>
  );
};

export default ViewBoardCategory;
