'use client';

import { FC, useContext, useEffect, useState } from 'react';
import ITabOption from 'ui/common/tab/define/ITabOption';
import RecruitSetting from 'ui/component/recruiter/recruitSetting/RecruitSetting';
import SolutionContext from '../../../../context/SolutionContext';
import RecruitSettingContext from '../../../../context/recruiter/RecruitSettingContext';
import RecruitSettingInjectedContext from '../../../../context/recruiter/RecruitSettingInjectedContext';
import { ViewModeType } from '../../../../interface/common/ViewMode';
import IViewContents from '../../../../interface/contents/IViewContents';
import { IRecruitment } from '../../../../interface/contents/recruitment/IRecruitment';
import { HasOption } from '../../../../interface/common/hasOption';
import { LANGUAGE } from '../../../../constants/language';

interface IProps {
  viewMode?: ViewModeType;
  viewRecruitment: IViewContents<IRecruitment>;
  isPreview?: boolean;
}

const RecruitViewSetting: FC<IProps & HasOption> = ({ viewMode, viewRecruitment, isPreview, options }) => {
  const {
    tabValue,
    keyword,
    careerType,
    workingArea,
    jobGroup,
    submissionStatus,
    setTabValue,
    setKeyword,
    setCareerType,
    setJobGroup,
    setWorkingArea,
    setSubmissionStatus,
  } = useContext(RecruitSettingContext);

  const { queryGetSettingData, getSetting } = useContext(RecruitSettingInjectedContext);
  const languageType = options && options['languageType'];

  const { solution } = useContext(SolutionContext);
  const [tabOption, setTabOption] = useState<ITabOption<string>[]>([]);
  const DEFAULT_TAB_OPTION = [{ value: '', label: languageType === LANGUAGE.KOR ? '전체' : 'ALL' }];

  useEffect(() => {
    getSetting?.();
  }, [getSetting]);

  useEffect(() => {
    if (!queryGetSettingData || !queryGetSettingData.tagTypeList) return;
    if (queryGetSettingData.tagTypeList.length === 0) return;
    const currentTagList = queryGetSettingData.tagTypeList.find(
      ({ tagType }) => tagType === queryGetSettingData.tagType
    );

    if (!currentTagList) {
      setTabOption(DEFAULT_TAB_OPTION);
      return;
    }

    setTabOption([
      ...DEFAULT_TAB_OPTION,
      ...currentTagList.tagList.map(({ tagSn, tagName }) => ({
        label: tagName,
        value: tagSn.toString(),
      })),
    ]);
    setTabValue('');
  }, [queryGetSettingData.tagTypeList]);

  const handleClickTab = (selectedTag: string) => {
    if (tabValue === selectedTag) {
      return;
    } else {
      setTabValue(selectedTag);
    }
  };

  return (
    <RecruitSetting
      solution={solution}
      viewMode={viewMode}
      selectorList={{
        tabOption: tabOption,
        filterTypeList: queryGetSettingData.filterTypeList,
        jobGroupList: queryGetSettingData.jobGroupList,
        careerTypeList: queryGetSettingData.careerTypeList,
        workingAreaList: queryGetSettingData.workingAreaList,
        submissionStatusList: queryGetSettingData.submissionStatusList,
      }}
      selectedList={{
        tabValue: tabValue,
        keyword: keyword,
        jobGroup: jobGroup,
        careerType: careerType,
        workingArea: workingArea,
        submissionStatus: submissionStatus,
      }}
      handleClickTab={handleClickTab}
      handleInputKeyword={setKeyword}
      handleClickJobGroupSnList={setJobGroup}
      handleClickCareerTypeList={setCareerType}
      handleClickWorkingAreaList={setWorkingArea}
      handleClickSubmissionStatusList={setSubmissionStatus}
      readOnly={isPreview}
      options={options}
    />
  );
};

export default RecruitViewSetting;
