import { GRID_POSITION_PROPS, POPUP_GRID, POPUP_STACK_OFFSET } from 'ui/constants/popup/popup.constant';
import { useCallback, useState } from 'react';
import { IGetPopupListV1Response, PopupGridAreaType } from 'ui/interface/popup/popup.interface';
import { UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { popupBlockFilter, getPopupMain, blockPopupTemplate, cx } from './PopupList';
import { HasOption } from '../../interface/common/hasOption';

export interface IPositionStyle {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  zIndex?: number;
}

export const PopupListPc = (props: UseQueryResult<IGetPopupListV1Response, AxiosError> & HasOption) => {
  const { data, isSuccess, options } = props;

  if (!data || !isSuccess) return <></>;
  const { popUpList: popupList } = data;
  const gridStackTable: Record<PopupGridAreaType, number> = {
    A0: 0,
    A1: 0,
    A2: 0,
    B0: 0,
    B1: 0,
    B2: 0,
    C1: 0,
    C0: 0,
    C2: 0,
  };

  const generatePopupPositionStyle = (gridAreaType: PopupGridAreaType, idx: number): IPositionStyle => {
    const value = gridStackTable[gridAreaType]++ * POPUP_STACK_OFFSET;
    const positionStyle = GRID_POSITION_PROPS[gridAreaType].reduce(
      (obj, key) => Object.assign(obj, { [key]: value + 'px' }),
      {}
    );
    return { zIndex: 11 + idx, ...positionStyle };
  };

  return (
    <>
      {popupList.filter(popupBlockFilter).map((popup, i) => (
        <PopupCard
          popupPositionStyle={generatePopupPositionStyle(popup.gridAreaType, i)}
          key={i}
          popupSn={popup.popUpSn}
          popupGridAreaType={popup.gridAreaType}
          previewPopupMain={getPopupMain(popup, false, options)}
        />
      ))}
    </>
  );
};

interface IPopupCard {
  previewPopupMain: React.ReactNode;
  popupSn: string;
  popupGridAreaType: PopupGridAreaType;
  popupPositionStyle: IPositionStyle;
}
const PopupCard = (props: IPopupCard) => {
  const { previewPopupMain, popupSn, popupGridAreaType, popupPositionStyle } = props;
  const [isDisplayed, setIsDisplayed] = useState(true);

  const getGridPosition = useCallback((position: number) => {
    const [_row, _col] = [position / POPUP_GRID.COL, position % POPUP_GRID.COL];
    const row = String.fromCharCode(65 + _row);
    const col = String.fromCharCode(65 + _col);

    return ['row-' + row, 'col-' + col];
  }, []);
  const gridToPositionNumber = (val: PopupGridAreaType) => {
    const [_row, col] = val.split('');
    const row = (_row.charCodeAt(0) - 65) * POPUP_GRID.COL;
    return row + +col;
  };

  const handleClosePopup = () => {
    setIsDisplayed(false);
  };
  const handleBlockPopup = () => {
    handleClosePopup();
    localStorage.setItem(blockPopupTemplate(popupSn), new Date().toLocaleDateString());
  };
  return (
    <div className={cx('preview-popup-container', ...getGridPosition(gridToPositionNumber(popupGridAreaType)))}>
      <div
        className={cx('preview-popup', ...getGridPosition(gridToPositionNumber(popupGridAreaType)))}
        style={{ ...popupPositionStyle, visibility: isDisplayed ? 'visible' : 'hidden' }}
      >
        <div className={cx('preview-main')}>{previewPopupMain}</div>
        <div className={cx('preview-buttons')}>
          <div className={cx('preview-button', 'button')} onClick={handleBlockPopup}>
            오늘은 그만보기
          </div>
          <div className={cx('div-hori')}></div>
          <div className={cx('preview-button', 'button')} onClick={handleClosePopup}>
            닫기
          </div>
        </div>
      </div>
    </div>
  );
};
