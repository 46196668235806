import { FC } from 'react';
import classnames from 'classnames/bind';
import styles from './ViewText.module.scss';
import SlateEditor from '../../../common/textEditor/SlateEditor';
import IViewContents from '../../../interface/contents/IViewContents';
import { IText } from '../../../interface/contents/text/IText';
import { ViewModeType } from '../../../interface/common/ViewMode';
import { HasOption } from '../../../interface/common/hasOption';
const cx = classnames.bind(styles);

interface IViewTextProps {
  viewMode?: ViewModeType;
  viewText: IViewContents<IText>;
  isPreview?: boolean;
}

const ViewText: FC<IViewTextProps & HasOption> = ({ viewMode, viewText, isPreview, options }) => {
  const { borderRadius, backgroundColor, padding, boxShadow } = viewText.style;
  const { paddingTop, paddingRight, paddingBottom, paddingLeft } = padding;
  const { leftTopRadius, rightTopRadius, rightDownRadius, leftDownRadius } = borderRadius;

  const value = typeof viewText.value === 'string' ? JSON.parse(viewText.value) : viewText.value;
  return (
    <div
      className={cx('content', viewMode, { boxShadow: boxShadow === 'ON' })}
      style={{
        backgroundColor,
        padding: `${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px`,
        borderRadius: `${leftTopRadius}px ${rightTopRadius}px ${rightDownRadius}px ${leftDownRadius}px`,
      }}
    >
      {/* 에디터 */}
      <SlateEditor
        editorClassName={cx('editor')}
        value={value || []}
        onChange={() => {}}
        disabled={true}
        viewMode={viewMode}
        isPreview={isPreview}
        options={options}
      />
    </div>
  );
};

export default ViewText;
