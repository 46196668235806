import { FC, ReactNode } from 'react';
import { BoardInjectedContextProvider } from './BoardInjectedContext';
import { BoardSettingContextProvider } from './BoardSettingContext';

const BoardProvider: FC<{ children: ReactNode }> = (props) => {
  return (
    <BoardInjectedContextProvider>
      <BoardSettingContextProvider>{props.children}</BoardSettingContextProvider>
    </BoardInjectedContextProvider>
  );
};

export default BoardProvider;
