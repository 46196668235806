import classNames from 'classnames/bind';
import { IAccordionItem, IAccordionTab } from '../../../interface/contents/accordion/IAccordion';
import Icon from '../../../common/icon/Icon';
import SlateEditor from '../../../common/textEditor/SlateEditor';
import style from './ViewAccordionItem.module.scss';
import { useMemo } from 'react';
import { OnOffType, isOnOff } from '../../../constants/common';
import { HasOption } from '../../../interface/common/hasOption';

const cx = classNames.bind(style);

interface IAccordionItemView {
  accordionTabList: IAccordionTab[];
  accordion: IAccordionItem;
  isOpen: boolean;
  setIsOpen: (setter: (prev: boolean) => boolean) => void;
  tabStatusType: OnOffType;
}
export const ViewAccordionItem = (props: IAccordionItemView & HasOption) => {
  const { accordion, isOpen, setIsOpen, accordionTabList, tabStatusType, options } = props;
  const { title, body } = accordion;

  const tabListView = useMemo(() => {
    return accordion.accordionTabIdList
      .map((tabId) => accordionTabList.find((tabItem) => tabItem.id === tabId))
      .filter((v) => v);
  }, [accordion.accordionTabIdList, accordionTabList]);

  return (
    <div
      className={cx('container', {
        ['--active']: isOpen,
      })}
    >
      <div
        className={cx('item-title', 'wrapper')}
        onMouseDown={() => {
          setIsOpen((prev) => !prev);
        }}
      >
        {isOnOff(tabStatusType) && !!tabListView.length && (
          <div className={cx('tab-list')}>
            {tabListView.map((tab) => (
              <div key={tab?.id} className={cx('tab-item')}>
                {tab?.name}
              </div>
            ))}
          </div>
        )}
        <div className={cx('title-wrapper')}>
          <div className={cx('editor')}>
            <SlateEditor disabled value={title} viewMode={'PC'} onChange={() => {}} options={options} />
          </div>
          <Icon className={cx('open-toggler')} size={38} name={isOpen ? 'arrow_top_line' : 'arrow_bottom_line'} />
        </div>
      </div>
      {isOpen && (
        <>
          <div className={cx('div-hori')}></div>
          <div className={cx('item-body', 'editor')}>
            <SlateEditor disabled value={body} viewMode={'PC'} onChange={() => {}} options={options} />
          </div>
        </>
      )}
    </div>
  );
};
