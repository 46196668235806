import { FC, useMemo } from 'react';
import classnames from 'classnames/bind';
import styles from './ViewImage.module.scss';
import IViewContents from '../../../interface/contents/IViewContents';
import { IImage } from '../../../interface/contents/image/IImage';
import { VIEW_MODE, ViewModeType } from '../../../interface/common/ViewMode';
import { LINK_TYPE } from '../../../interface/common/Link';
import { getImageURL } from 'ats-editor/src/utils/common/file.utils';
import { HasOption, PageList } from '../../../interface/common/hasOption';
import useClickLinkedElement from '../../../hook/link/useClickLinkedElement';
import IImageMobileStyle from '../../../interface/contents/image/IImageMobileStyle';
import IImageStyle from '../../../interface/contents/image/IImageStyle';
import { ON } from '../../../constants/common';
import { convertAlignToFlex, Align } from '../../../interface/common/Align';
import { URL_TYPE } from '../../../interface/header/IMenu';
import { checkValidLink } from '../../../util/link.utils';

const cx = classnames.bind(styles);

interface IViewImageProps {
  viewMode?: ViewModeType;
  viewImage: IViewContents<IImage>;
  isPreview?: boolean;
  isEdit?: boolean;
}

const ViewImage: FC<IViewImageProps & HasOption> = ({ viewMode, viewImage, isPreview, isEdit, options }) => {
  const pageLinkList = options?.pageLinkList as PageList;
  const { handleClickLinkedElement } = useClickLinkedElement(pageLinkList);
  const languageType = options && options['languageType'];
  const { companyName, getPathName, findPageTitle } = options?.pageMetadataHooks ?? {
    companyName: undefined,
    getPathName: undefined,
    findPageTitle: undefined,
  };
  const ImageStyle = (): IImageStyle | IImageMobileStyle => {
    //1. 모바일에서 모바일옵션을 사용한다면 우선순위가 높음
    if (viewMode === VIEW_MODE.MOBILE && viewImage?.mobileStyleStatus === ON) return viewImage.mobileStyle!;
    return viewImage.style;
  };

  const {
    align,
    roundStatusType,
    monochromeStatusType,
    shadowStatusType,
    size,
    borderRadius: { leftTopRadius, rightTopRadius, rightDownRadius, leftDownRadius },
  } = ImageStyle();

  const pcViewImageUrl = getImageURL({
    fileUid: viewImage?.value?.desktopImage?.fileUid,
    fileUrl: viewImage?.value?.desktopImage?.fileUrl,
  });

  const mobileViewImageUrl = getImageURL({
    fileUid: viewImage?.value?.mobileImage?.fileUid,
    fileUrl: viewImage?.value?.mobileImage?.fileUrl,
  });

  const isExternal =
    viewImage?.link?.linkType === LINK_TYPE.URL || viewImage?.link?.pageUrl?.urlType === URL_TYPE.EXTERNAL;
  const hasLink = checkValidLink(viewImage?.link);

  const borderRadiusStyle = useMemo(
    () => ({
      borderRadius: `${leftTopRadius}px ${rightTopRadius}px ${rightDownRadius}px ${leftDownRadius}px`,
    }),
    [leftDownRadius, leftTopRadius, rightDownRadius, rightTopRadius]
  );

  const imageSrc = useMemo(() => {
    if (viewMode === VIEW_MODE.MOBILE && mobileViewImageUrl) return mobileViewImageUrl;
    return pcViewImageUrl ?? '';
  }, [pcViewImageUrl, mobileViewImageUrl, viewMode]);

  const imageAlt = useMemo(() => {
    if (!companyName || !getPathName || !findPageTitle) return '';
    if (isExternal) return `${viewImage?.link?.url}와(과) 연결되었습니다.`;
    if (viewImage?.link?.url) return `${companyName}의 ${findPageTitle(viewImage?.link?.url)}와(과) 연결되었습니다.`;
    return `${companyName}의 ${findPageTitle(getPathName())}을(를) 소개하는 이미지입니다.`;
  }, [companyName, getPathName, findPageTitle, viewImage?.link?.url, isExternal]);

  if (!viewImage || !viewImage?.value) return null;
  // 링크 모바일 이미지 등록 여부와 관계없이 PC와 모바일 이미지가 PC에서 설정한 링크로 동일하게 이동된다

  const getImgEl = () => {
    if (!pcViewImageUrl && !mobileViewImageUrl) return null;
    return (
      <div className={cx('wrapper')} style={{ justifyContent: convertAlignToFlex(Align[align]) }}>
        <div
          className={cx('imageSizeArea', align, { empty: !imageSrc }, { cursor: hasLink })}
          style={{ width: `${size}%`, ...borderRadiusStyle }}
          onClick={() => handleClickLinkedElement(viewImage?.link, isPreview, isEdit, languageType)}
        >
          <img
            className={cx('img', {
              mono: monochromeStatusType === ON,
              shadow: shadowStatusType === ON,
              round: roundStatusType === ON,
            })}
            src={imageSrc}
            alt={imageAlt}
          />
        </div>
      </div>
    );
  };

  return getImgEl();
};

export default ViewImage;
