import { ViewModeType } from 'ui/interface/common/ViewMode';
import { getImageURL } from 'ats-editor/src/utils/common/file.utils';
import { useMemo } from 'react';
import { VIEW_MODE } from '../../../interface/common/ViewMode';
import IViewLayout from '../../../interface/layout/IViewLayout';
import { Ratio } from '../../../interface/common/Ratio';
import { ILayout } from '../../../interface/layout/ILayout';
import { BANNER_MEDIA } from '../../../interface/layout/banner/IBanner';
import { bannerImageAcceptList, bannerVideoAcceptList } from '../../../constants/editor/banner.constant';

interface IProps {
  layout: IViewLayout | ILayout;
  viewMode?: ViewModeType;
}

const useBannerFile = ({ layout, viewMode = VIEW_MODE.PC }: IProps) => {
  const { ratioType, size } = layout.banner!.style;

  const bannerDesktopImageUrl = getImageURL({
    fileUid: layout.banner?.value?.desktopImage?.fileUid,
    fileUrl: layout.banner?.value?.desktopImage?.fileUrl,
  });
  const bannerMobileImageUrl = getImageURL({
    fileUid: layout.banner?.value?.mobileImage?.fileUid,
    fileUrl: layout.banner?.value?.mobileImage?.fileUrl,
  });

  const fileUrl = useMemo(() => {
    if (viewMode === VIEW_MODE.PC || viewMode === VIEW_MODE.TABLET) return bannerDesktopImageUrl;
    if (viewMode === VIEW_MODE.MOBILE) {
      if (!bannerMobileImageUrl) return bannerDesktopImageUrl;
      return bannerMobileImageUrl;
    }
  }, [bannerMobileImageUrl, bannerDesktopImageUrl, viewMode]);

  const fileType = useMemo(() => {
    if (bannerImageAcceptList.some((accept) => fileUrl?.endsWith(accept))) return BANNER_MEDIA.IMAGE;
    if (bannerVideoAcceptList.some((accept) => fileUrl?.endsWith(accept))) return BANNER_MEDIA.VIDEO;
    return null;
  }, [fileUrl]);

  const fileWidth = useMemo(() => {
    return ratioType === Ratio.FULL ? '100%' : `${size.width}px`;
  }, [ratioType, size.width]);

  return { bannerDesktopImageUrl, bannerMobileImageUrl, fileUrl, fileType, fileWidth };
};

export default useBannerFile;
