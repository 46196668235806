import { FC } from 'react';
import { VIEW_MODE, ViewModeType } from '../../interface/common/ViewMode';
import classnames from 'classnames/bind';
import styles from './ViewBlock.module.scss';
import IViewBlock from '../../interface/block/IViewBlock';
import ViewGroup from '../group/ViewGroup';
import { DEFAULT_CONTENTS_GROUP_GAP } from 'ui/constants/editor/page.constant';
import IPageStyle from '../../interface/page/IPageStyle';
import useIntersectionObserver from '../../hook/useIntersectionObserver';
import useScrollToBottom from '../../hook/useScrollToBottom';
import { HasOption } from '../../interface/common/hasOption';
import { isOnOff } from '../../constants/common';

const cx = classnames.bind(styles);

interface IViewBlockProps {
  viewMode?: ViewModeType;
  pageStyle: IPageStyle;
  viewBlock: IViewBlock;
  isCapture?: boolean;
  isPreview?: boolean;
  isEdit?: boolean;
  previewTabType?: ViewModeType;
}

const ViewBlock: FC<IViewBlockProps & HasOption> = ({
  viewMode,
  pageStyle,
  viewBlock,
  isCapture = false,
  isPreview,
  isEdit,
  previewTabType,
  options,
}) => {
  const gap = isOnOff(pageStyle.isToggledGap)
    ? pageStyle.contentsGroupGap ?? DEFAULT_CONTENTS_GROUP_GAP
    : DEFAULT_CONTENTS_GROUP_GAP;
  const { padding, backgroundColor } = viewBlock.style;
  const { paddingTop, paddingRight, paddingBottom, paddingLeft } = padding;

  // 스크롤 hook
  const { isIntersection, setIsIntersection } = useIntersectionObserver({
    idList: [viewBlock.id],
  });

  // 바닥 감지 hook
  useScrollToBottom({
    onBottom: () => setIsIntersection(true),
  });

  const getPadding = () => {
    const top = paddingTop;
    const right = paddingRight;
    const bottom = paddingBottom;
    const left = paddingLeft;
    return `${convertPadding(top)}px ${right}px ${convertPadding(bottom)}px ${left}px`;
  };

  const convertPadding = (padding: number) => {
    if (viewMode === VIEW_MODE.PC) return padding;
    if (viewMode === VIEW_MODE.TABLET) return padding;
    if (padding > 64) return 64;
    if (padding >= 64) return 40;
    if (padding >= 24) return 16;
    return padding;
  };

  const convertGap = (gap: number) => {
    if (viewMode === VIEW_MODE.PC) return gap;
    if (viewMode === VIEW_MODE.TABLET) return gap;
    if (gap >= 16) return 16;
    return gap;
  };

  const renderOrder = () => {
    return viewBlock.groups
      .slice()
      .sort((a, b) => {
        switch (viewMode) {
          case VIEW_MODE.MOBILE:
            return a.mobileSortOrder - b.mobileSortOrder;
          default:
            return a.pcSortOrder - b.pcSortOrder;
        }
      })
      .map((viewGroup) => (
        <ViewGroup
          key={viewGroup.id}
          viewMode={viewMode}
          pageStyle={pageStyle}
          viewGroup={viewGroup}
          blockType={viewBlock.type}
          contentsGroupGap={convertGap(gap)}
          isPreview={isPreview}
          isEdit={isEdit}
          previewTabType={previewTabType}
          options={options}
        />
      ));
  };

  return (
    <div id={viewBlock.id} className={cx('container', viewBlock.type, viewMode)} style={{ backgroundColor }}>
      <div className={cx('content', { fadeIn: !isCapture && isIntersection }, { isCapture })}>
        <div
          className={cx('group-container')}
          style={{
            padding: getPadding(),
            gap: `${convertGap(gap)}px`,
          }}
        >
          {renderOrder()}
        </div>
        <div className={cx('group-width-calculator-container')}>
          {viewBlock.groups.map(({ id: groupId }) => (
            <div
              key={groupId}
              className={cx(`group-width-${groupId}`)}
              style={{
                gap: `${convertGap(gap)}px`,
              }}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewBlock;
