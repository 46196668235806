import { ViewModeType } from '../../../interface/common/ViewMode';
import IViewLayout from '../../../interface/layout/IViewLayout';
import { FC } from 'react';
import classnames from 'classnames/bind';
import styles from './ViewBannerText.module.scss';
import SlateEditor from '../../../common/textEditor/SlateEditor';
import { HasOption } from '../../../interface/common/hasOption';
const cx = classnames.bind(styles);

interface IViewBannerTextProps {
  viewMode?: ViewModeType;
  viewLayout: IViewLayout;
  isPreview?: boolean;
}

const ViewBannerText: FC<IViewBannerTextProps & HasOption> = ({ viewMode, viewLayout, isPreview, options }) => {
  const { text } = viewLayout.banner!;
  const value = typeof text === 'string' ? JSON.parse(text) : text;

  if (!value) return null;

  return (
    <div className={cx('container')}>
      <div className={cx('content')}>
        <SlateEditor
          value={value}
          onChange={() => {}}
          disabled={true}
          viewMode={viewMode}
          isPreview={isPreview}
          options={options}
        />
      </div>
    </div>
  );
};

export default ViewBannerText;
