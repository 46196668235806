import classNames from 'classnames/bind';
import { FC } from 'react';
import { ViewModeType } from '../../../interface/common/ViewMode';
import IViewContents from '../../../interface/contents/IViewContents';
import { IRecruitment } from '../../../interface/contents/recruitment/IRecruitment';
import style from './ViewRecruitment.module.scss';
import RecruitViewList from './list/RecruitViewList';
import RecruitViewSetting from './setting/RecruitViewSetting';
import { HasOption } from '../../../interface/common/hasOption';

const cx = classNames.bind(style);

interface IProps {
  viewMode?: ViewModeType;
  viewRecruitment: IViewContents<IRecruitment>;
  isPreview?: boolean;
  isEdit?: boolean;
}

const ViewRecruitment: FC<IProps & HasOption> = ({ viewMode, viewRecruitment, isPreview, isEdit, options }) => {
  return (
    <div className={cx('container')}>
      <RecruitViewSetting
        viewMode={viewMode}
        viewRecruitment={viewRecruitment}
        isPreview={isPreview}
        options={options}
      />
      <RecruitViewList
        viewMode={viewMode}
        viewRecruitment={viewRecruitment}
        isPreview={isPreview}
        isEdit={isEdit}
        options={options}
      />
    </div>
  );
};

export default ViewRecruitment;
