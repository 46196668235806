import { FC } from 'react';
import { ViewModeType } from '../../../interface/common/ViewMode';
import IViewLayout from '../../../interface/layout/IViewLayout';
import classnames from 'classnames/bind';
import styles from './ViewBannerColor.module.scss';
import ViewBannerText from './ViewBannerText';
import { Ratio } from '../../../interface/common/Ratio';
import { HasOption } from '../../../interface/common/hasOption';
const cx = classnames.bind(styles);

interface IViewBannerColorProps {
  viewMode?: ViewModeType;
  viewLayout: IViewLayout;
  isPreview?: boolean;
  onClick?: () => void;
}

const ViewColor: FC<IViewBannerColorProps & HasOption> = ({ viewMode, viewLayout, isPreview, onClick, options }) => {
  const { ratioType, backgroundColor, size } = viewLayout.banner!.style;

  const getWidth = () => {
    if (ratioType === Ratio.FULL) return '100%';
    else return `${size.width}px`;
  };

  return (
    <div className={cx('wrapper')} style={{ width: getWidth(), height: `${size.height}px` }}>
      <div
        className={cx('banner', ratioType, { cursor: onClick })}
        style={{
          backgroundColor: backgroundColor,
        }}
        onClick={() => onClick?.()}
      >
        <ViewBannerText viewMode={viewMode} viewLayout={viewLayout} isPreview={isPreview} options={options} />
      </div>
    </div>
  );
};

export default ViewColor;
