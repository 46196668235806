import React, { FC } from 'react';
import classnames from 'classnames/bind';
import styles from './ViewGroup.module.scss';
import { VIEW_MODE, ViewModeType } from '../../interface/common/ViewMode';
import IViewGroup from '../../interface/group/IViewGroup';
import ViewContents from '../contents/ViewContents';
import IPageStyle from '../../interface/page/IPageStyle';
import { Block, BlockType } from '../../interface/block/IBlock';
import { HasOption } from '../../interface/common/hasOption';
import { DEFAULT_PAGE_STYLE } from 'ats-editor/src/constant/DefaultEditor';
import { isOnOff } from '../../constants/common';
const cx = classnames.bind(styles);

interface IViewGroupProps {
  viewMode?: ViewModeType;
  pageStyle: IPageStyle;
  viewGroup: IViewGroup;
  blockType: BlockType;
  contentsGroupGap: number;
  isPreview?: boolean;
  previewTabType?: ViewModeType;
  isEdit?: boolean;
}

const ViewGroup: FC<IViewGroupProps & HasOption> = ({
  viewMode,
  pageStyle,
  viewGroup,
  blockType,
  contentsGroupGap,
  isPreview,
  isEdit,
  previewTabType,
  options,
}) => {
  const { contents } = viewGroup;
  const gap = isOnOff(pageStyle.isToggledGap)
    ? `${(pageStyle ?? DEFAULT_PAGE_STYLE).contentsGap}px`
    : `${DEFAULT_PAGE_STYLE.contentsGap}px`;

  const getWidth = () => {
    if (viewMode === VIEW_MODE.TABLET && blockType === Block.DEPTH4) return `calc(50% - ${contentsGroupGap / 2}px)`;
    return '100%';
  };

  const contentsEls = contents.map((viewContents) => {
    return (
      <ViewContents
        key={viewContents.id}
        viewMode={viewMode}
        viewContents={viewContents}
        isPreview={isPreview}
        isEdit={isEdit}
        previewTabType={previewTabType}
        options={options}
      />
    );
  });

  return (
    <div
      className={cx('container')}
      style={{
        width: getWidth(),
      }}
    >
      <div
        className={cx('content')}
        style={{
          gap,
        }}
      >
        {contentsEls}
      </div>
    </div>
  );
};

export default ViewGroup;
