import classNames from 'classnames/bind';
import style from './ViewAccordion.module.scss';
import { ViewModeType } from '../../../interface/common/ViewMode';
import IViewContents from '../../../interface/contents/IViewContents';
import { AccordionItemIdType, AccordionTabIdType, IAccordion } from '../../../interface/contents/accordion/IAccordion';
import { useMemo, useRef, useState } from 'react';
import { ACCORDION_TAB_ALL, useViewAccordion } from './useViewAccordion';
import { ViewAccordionHeader } from './ViewAccordionHeader';
import { ViewAccordionItem } from './ViewAccordionItem';
import NoResult from '../../common/NoResult';
import { HasOption } from '../../../interface/common/hasOption';

interface IProps {
  viewMode?: ViewModeType;
  isPreview?: boolean;
  viewAccordion: IViewContents<IAccordion>;
}
export const cx = classNames.bind(style);
const ViewAccordion = ({ viewAccordion, viewMode, options }: IProps & HasOption) => {
  const accordionRef = useRef(null);
  const isMobile = viewMode === 'TABLET' || viewMode === 'MOBILE';
  const [keyword, setKeyword] = useState('');
  const [accordionItemOpenStatusMap, setAccordionItemOpenStatusMap] = useState<Record<AccordionItemIdType, boolean>>(
    {}
  );
  const tabSwipeOption = useMemo(() => {
    return { enabled: viewMode === 'MOBILE' };
  }, [viewMode]);
  const [currentTabId, setCurrentTabId] = useState<AccordionTabIdType>(ACCORDION_TAB_ALL);
  const { accordionListSearchFilterView } = useViewAccordion(
    viewAccordion.value,
    keyword,
    currentTabId,
    setAccordionItemOpenStatusMap
  );

  const setIsOpenAccordionItem = (accordionId: AccordionItemIdType) => (setter: (prev: boolean) => boolean) => {
    setter(!!accordionItemOpenStatusMap[accordionId]);
    setAccordionItemOpenStatusMap((prev) => ({
      ...prev,
      [accordionId]: setter(!!accordionItemOpenStatusMap[accordionId]),
    }));
  };

  return (
    <div className={cx('container')} ref={accordionRef}>
      <ViewAccordionHeader
        isMobile={isMobile}
        keyword={keyword}
        setKeyword={setKeyword}
        viewAccordion={viewAccordion}
        currentTabId={currentTabId}
        setCurrentTabId={setCurrentTabId}
        tabSwipeOption={tabSwipeOption}
        options={options}
      />
      <div className={cx('content')}>
        {accordionListSearchFilterView.map((item) => (
          <ViewAccordionItem
            accordionTabList={viewAccordion.value.accordionTabList ?? []}
            isOpen={!!accordionItemOpenStatusMap[item!.id]}
            setIsOpen={setIsOpenAccordionItem(item!.id)}
            accordion={item!}
            key={item!.id}
            tabStatusType={viewAccordion.value.tabStatusType}
            options={options}
          />
        ))}
      </div>
      {!keyword && currentTabId === ACCORDION_TAB_ALL && !accordionListSearchFilterView.length && (
        <NoResult title={'탭에 포함된 콘텐츠가 없어요.'} subTitle={''} />
      )}
      {!keyword && currentTabId !== ACCORDION_TAB_ALL && !accordionListSearchFilterView.length && (
        <NoResult title={'탭에 포함된 콘텐츠가 없어요.'} subTitle={'다른 탭으로 이동해보는 건 어떠세요?'} />
      )}
      {keyword && !accordionListSearchFilterView.length && (
        <NoResult title={'검색 결과가 없어요.'} subTitle={'혹시 다른 키워드로 검색해보는 건 어떠세요?'} />
      )}
    </div>
  );
};

export default ViewAccordion;
