import classnames from 'classnames/bind';
import style from './PopupImage.module.scss';
import { IPositionStyle } from './PopupText';
import Icon from '../../common/icon/Icon';
import { COLORS } from '../../constants/colors';

const cx = classnames.bind(style);

interface IPopupImage {
  width?: number;
  height?: number;
  url?: string | null;
  positionStyle?: IPositionStyle;
  mobileImageFileUrl?: string;
  pcImageFileUrl?: string;
  isMobile?: boolean;
}
const PopupImage = (props: IPopupImage) => {
  const { width, height, url, positionStyle, mobileImageFileUrl, pcImageFileUrl, isMobile = false } = props;

  const sizeStyle = {
    width: isMobile ? '100%' : width,
    height: isMobile ? '100%' : height,
  };

  return (
    <div
      className={cx({ 'cursor-pointer': !!url })}
      onClick={() => {
        if (url) window.open(url, '_blank');
      }}
    >
      {pcImageFileUrl ? (
        <img
          style={{ ...sizeStyle, ...positionStyle }}
          src={isMobile ? mobileImageFileUrl || pcImageFileUrl || '' : pcImageFileUrl}
          alt={'popupPcImg'}
          className={cx('popup-main')}
        />
      ) : (
        <div style={{ width, height }} className={cx('popup-main', '--empty')}>
          <Icon name={'photo_filled'} size={64} color={COLORS.gray400} />
        </div>
      )}
    </div>
  );
};

export default PopupImage;
