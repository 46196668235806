import { FC, useState, useEffect, useMemo, useRef } from 'react';
import { VIEW_MODE, ViewModeType } from '../../../interface/common/ViewMode';
import IViewLayout from '../../../interface/layout/IViewLayout';
import classnames from 'classnames/bind';
import styles from './ViewBannerImage.module.scss';
import ViewBannerText from './ViewBannerText';
import { aToHex } from '../../../common/colorPicker/utils/convert';
import { HasOption } from '../../../interface/common/hasOption';
import useBannerFile from './useBannerFile';
import { BANNER_MEDIA } from '../../../interface/layout/banner/IBanner';
import useFileSize from '../../../hook/useFileSize';
const cx = classnames.bind(styles);

interface IViewBannerImageProps {
  viewMode?: ViewModeType;
  viewLayout: IViewLayout;
  isCapture?: boolean;
  isPreview?: boolean;
  onClick?: () => void;
}

const ViewBannerImage: FC<IViewBannerImageProps & HasOption> = ({
  viewMode,
  viewLayout,
  isCapture = false,
  isPreview,
  onClick,
  options,
}) => {
  const { ratioType, size, overlayColor, overlayRatio } = viewLayout.banner!.style;
  const { companyName, getPathName, findPageTitle } = options?.pageMetadataHooks ?? {
    companyName: undefined,
    getPathName: undefined,
    findPageTitle: undefined,
  };

  const mediaWrapperRef = useRef<HTMLDivElement>(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const { fileUrl: _fileUrl, fileWidth, fileType } = useBannerFile({ layout: viewLayout, viewMode });
  const fileUrl = _fileUrl ?? '';
  const { fileSize: mediaFileSize } = useFileSize({ fileType, fileUrl });

  const isLargeMobileMedia = useMemo(
    () => viewMode === VIEW_MODE.MOBILE && mediaFileSize.height > 800,
    [viewMode, mediaFileSize.height]
  );

  const getAltText = () => {
    if (!companyName || !getPathName || !findPageTitle) return '';
    return `${companyName}의 ${findPageTitle(getPathName())}을(를) 소개하는 배너입니다.`;
  };

  const onLoadedVideoData = () => {
    setIsVideoLoaded(true);
  };

  useEffect(() => {
    if (fileType === BANNER_MEDIA.VIDEO && mediaWrapperRef.current && isCapture && isVideoLoaded) {
      const $video = mediaWrapperRef.current.querySelector('video') as HTMLVideoElement;
      const dataURL = getCapturedVideoDataURL($video, mediaWrapperRef.current);
      addCapturedVideoImageToMediaWrapper(dataURL, mediaWrapperRef.current);
    }

    function getCapturedVideoDataURL($video: HTMLVideoElement, $mediaWrapper: HTMLDivElement) {
      const $canvas = document.createElement('canvas');
      $canvas.width = $video.videoWidth;
      $canvas.height = $video.videoHeight;

      $canvas.getContext('2d')!.drawImage($video, 0, 0, $video.videoWidth, $video.videoHeight);
      $mediaWrapper.removeChild($video);
      $mediaWrapper.appendChild($canvas);
      const dataURL = $canvas.toDataURL('image/png');
      $mediaWrapper.removeChild($canvas);

      return dataURL;
    }

    function addCapturedVideoImageToMediaWrapper(dataURL: string, $mediaWrapper: HTMLDivElement) {
      const $capturedImage = document.createElement('div');
      $capturedImage.className = cx('media', 'capture');
      $capturedImage.style.backgroundImage = `url('${dataURL}')`;
      $capturedImage.style.width = fileWidth;
      $capturedImage.style.height = viewMode === VIEW_MODE.MOBILE ? `${mediaFileSize.height}px` : `${size.height}px`;
      $capturedImage.style.position = 'absolute';
      $capturedImage.style.top = '0';
      $mediaWrapper.appendChild($capturedImage);
    }
  }, [isVideoLoaded]);

  const getMedia = () => {
    switch (fileType) {
      case BANNER_MEDIA.IMAGE:
        return isCapture ? (
          <div
            className={cx('media', 'capture')}
            style={{
              backgroundImage: `url(${fileUrl})`,
            }}
          />
        ) : (
          <img className={cx('media')} src={fileUrl} alt={getAltText()} />
        );
      case BANNER_MEDIA.VIDEO:
        return isCapture ? (
          <video className={cx('media')} crossOrigin={'anonymous'} onLoadedData={onLoadedVideoData} autoPlay>
            <source src={fileUrl} />
          </video>
        ) : (
          <video className={cx('media')} src={fileUrl} autoPlay loop muted playsInline />
        );
      default:
        return null;
    }
  };

  if (!fileUrl) return null;

  return (
    <div
      className={cx('wrapper', viewMode)}
      style={{
        width: fileWidth,
        height: viewMode === VIEW_MODE.MOBILE ? `${mediaFileSize.height}px` : `${size.height}px`,
      }}
    >
      <div className={cx('imageSizeWrap', ratioType, { cursor: onClick })} onClick={() => onClick?.()}>
        <div
          className={cx('media-wrapper', viewMode, { correctLargeMobile: isLargeMobileMedia, hover: onClick })}
          ref={mediaWrapperRef}
        >
          {getMedia()}
        </div>
        <div className={cx('overlay')} style={{ backgroundColor: `${overlayColor}${aToHex(overlayRatio / 100)}` }} />
        <ViewBannerText viewMode={viewMode} viewLayout={viewLayout} isPreview={isPreview} options={options} />
      </div>
    </div>
  );
};

export default ViewBannerImage;
