import { FC, ReactNode, createContext, useState } from 'react';

interface IBoardSettingContextValueProps {
  children: ReactNode;
}

interface IBoardSettingContextValue {
  selectedCategory: string;
  setSelectedCategory: (value: string) => void;
}

const initialBoardSettingContextValue: IBoardSettingContextValue = {
  selectedCategory: '',
  setSelectedCategory: () => {},
};

const BoardSettingContext = createContext(initialBoardSettingContextValue);

export const BoardSettingContextProvider: FC<IBoardSettingContextValueProps> = ({ children }) => {
  const [selectedCategory, setSelectedCategory] = useState('');
  return (
    <BoardSettingContext.Provider
      value={{
        selectedCategory,
        setSelectedCategory,
      }}
    >
      {children}
    </BoardSettingContext.Provider>
  );
};

export default BoardSettingContext;
