import { OnOffType } from '../../../constants/common';
import { AlignType } from '../../common/Align';
import { BorderRadiusStyle } from '../../common/Border';

export const VIDEO_SIZE_SIMPLE = {
  SMALL: 'S',
  MEDIUM: 'M',
  LARGE: 'L',
};

export const VIDEO_SIZE = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
  CUSTOM: 'CUSTOM',
} as const;

export type VideoSizeType = keyof typeof VIDEO_SIZE;
export type VideoSizeSimpleType = keyof typeof VIDEO_SIZE_SIMPLE;
export const videoSizeList = Object.keys(VIDEO_SIZE) as VideoSizeType[];

export interface IVideoStyle {
  sizeType: VideoSizeType;
  sizePercentage: number;
  alignType: AlignType;
  marginFillStatus: OnOffType;
  repeatPlayStatus: OnOffType;
  autoPlayStatus: OnOffType;
  fullScreenStatus: OnOffType;
  pipModeStatus: OnOffType;
  borderRadius: BorderRadiusStyle;
}
