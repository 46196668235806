import { VIDEO_SIZE, VideoSizeType } from '../../interface/contents/video/IVideoStyle';
import { EDITOR_BLOCK_WIDTH } from './block.constant';

export const VIDEO_RATIO = 9 / 16; // 가로:세로 = 16:9

export const VIDEO_SIZE_FIXED: Record<VideoSizeType, number> = {
  [VIDEO_SIZE.SMALL]: 640,
  [VIDEO_SIZE.MEDIUM]: 960,
  [VIDEO_SIZE.LARGE]: EDITOR_BLOCK_WIDTH,
  [VIDEO_SIZE.CUSTOM]: 0,
};
