import useBoardContext from '@hook/useBoardContext';
import useRecruitmentContext from '@hook/useRecruitmentContext';
import { useMemo } from 'react';
import { IViewPageProps } from 'ui/component/page/useViewPage';

const useViewPage = () => {
  const { viewRecruitmentProps } = useRecruitmentContext();
  const { viewBoardProps } = useBoardContext();
  const viewPageProps: IViewPageProps = useMemo(() => {
    return {
      viewRecruitmentProps: viewRecruitmentProps,
      viewBoardProps: viewBoardProps,
    };
  }, [viewRecruitmentProps, viewBoardProps]);

  return {
    viewPageProps,
  };
};

export default useViewPage;
