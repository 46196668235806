'use client';

import { useEffect, useState } from 'react';

const useIsMounted = (callback?: () => void) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    (async () => {
      callback && (await callback());
      setIsMounted(true);
    })();
  }, [callback]);

  return { isMounted };
};

export default useIsMounted;
