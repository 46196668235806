import IViewContents from '../../../interface/contents/IViewContents';
import { AccordionTabIdType, IAccordion } from '../../../interface/contents/accordion/IAccordion';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { ACCORDION_TAB_ALL } from './useViewAccordion';
import Tab from '../../../common/tab/Tab';
import { isOnOff } from '../../../constants/common';
import Icon from '../../../common/icon/Icon';
import TextFieldBase from '../../../common/textfield/TextFieldBase';
import { COLORS } from '../../../constants/colors';
import { cx } from './ViewAccordion';
import { ISwipeOption } from '../../../common/tab/define/ITabOption';
import { HasOption } from '../../../interface/common/hasOption';
import { LANGUAGE } from '../../../constants/language';

interface IEditorAccordionHeader {
  keyword: string;
  setKeyword: Dispatch<SetStateAction<string>>;
  viewAccordion: IViewContents<IAccordion>;
  currentTabId: AccordionTabIdType;
  setCurrentTabId: Dispatch<SetStateAction<AccordionTabIdType>>;
  isMobile: boolean;
  tabSwipeOption: ISwipeOption;
}
export const ViewAccordionHeader = (props: IEditorAccordionHeader & HasOption) => {
  const { viewAccordion, currentTabId, setCurrentTabId, keyword, setKeyword, isMobile, tabSwipeOption, options } =
    props;
  const { tabStatusType, accordionTabList: _accordionTabList } = viewAccordion.value;
  const accordionTabList = useMemo(() => _accordionTabList ?? [], [_accordionTabList]);
  const languageType = options && options['languageType'];
  const tabOptions = useMemo(() => {
    const newTabList = accordionTabList.map((tabItem) => ({ value: tabItem.id, label: tabItem.name })) ?? [];
    newTabList.splice(0, 0, { value: ACCORDION_TAB_ALL, label: languageType === LANGUAGE.KOR ? '전체' : 'ALL' });
    return newTabList;
  }, [accordionTabList]);

  const isOverLinebreakTagNumber = accordionTabList.length > 5;

  return (
    <div className={cx({ header: isOnOff(tabStatusType) }, { ['--mobile']: isMobile })}>
      {isOnOff(tabStatusType) && (
        <div className={cx('tab-list', { ['--large']: isOverLinebreakTagNumber }, { ['--mobile']: isMobile })}>
          <Tab
            className={cx('tab')}
            options={tabOptions}
            value={currentTabId}
            onChange={(val) => setCurrentTabId(val)}
            swipeOption={tabSwipeOption}
          />
        </div>
      )}
      <TextFieldBase
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
        className={cx('searchbar', { ['--mono']: !isOverLinebreakTagNumber })}
        size={'lg'}
        beforeInput={<Icon color={COLORS.gray600} name={'search_line'} />}
        placeholder={languageType === LANGUAGE.KOR ? '제목과 내용으로 검색할 수 있어요.' : 'Search'}
      />
    </div>
  );
};
