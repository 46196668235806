import { useState } from 'react';
import { IGetPopupListV1Response, IPopupWithSn } from 'ui/interface/popup/popup.interface';
import { UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { popupBlockFilter, blockPopupTemplate, cx, getPopupMain } from './PopupList';
import { HasOption } from '../../interface/common/hasOption';

export const PopupCardMobile = (props: UseQueryResult<IGetPopupListV1Response, AxiosError> & HasOption) => {
  const { data, isSuccess, options } = props;
  if (!data || !isSuccess) return <></>;

  return (
    <>
      {data.popUpList.filter(popupBlockFilter).map((popup) => (
        <PopupItemMobile key={popup.popUpSn} popup={popup} options={options} />
      ))}
    </>
  );
};
const PopupItemMobile = ({ popup, options }: { popup: IPopupWithSn } & HasOption) => {
  const [isDisplayed, setIsDisplayed] = useState(true);

  const handleClosePopup = () => {
    setIsDisplayed(false);
  };
  const handleBlockPopup = (popupSn: string) => () => {
    handleClosePopup();
    localStorage.setItem(blockPopupTemplate(popupSn), new Date().toLocaleDateString());
  };

  return (
    <div style={{ visibility: isDisplayed ? 'visible' : 'hidden' }} className={cx('preview-popup-mobile')}>
      <div className={cx('preview-main')}>{getPopupMain(popup, true, options)}</div>
      <div className={cx('preview-buttons')}>
        <div className={cx('preview-button', 'button')} onClick={handleBlockPopup(popup.popUpSn)}>
          오늘은 그만보기
        </div>
        <div className={cx('div-hori')}></div>
        <div className={cx('preview-button', 'button')} onClick={handleClosePopup}>
          닫기
        </div>
      </div>
    </div>
  );
};
