import { FC } from 'react';
import classnames from 'classnames/bind';
import styles from './ViewButton.module.scss';
import { IButton } from '../../../interface/contents/button/IButton';
import IViewContents from '../../../interface/contents/IViewContents';
import { VIEW_MODE, ViewModeType } from '../../../interface/common/ViewMode';
import { ON } from '../../../constants/common';
import useClickLinkedElement from '../../../hook/link/useClickLinkedElement';
import { HasOption, PageList } from '../../../interface/common/hasOption';

const cx = classnames.bind(styles);

interface IViewButtonProps {
  viewMode?: ViewModeType;
  viewButton: IViewContents<IButton>;
  isPreview?: boolean;
  isEdit?: boolean;
}

const ViewButton: FC<IViewButtonProps & HasOption> = ({ viewButton, isPreview, isEdit, viewMode, options }) => {
  const {
    align,
    flex,
    size: { width: sizeWidth, height: sizeHeight },
    backgroundColor,
    color,
    borderRadius,
    link,
  } = viewButton.style;

  const { leftTopRadius, rightTopRadius, rightDownRadius, leftDownRadius } = borderRadius;

  const hasLink = link.linkStatusType === ON;

  const pageLinkList = options?.pageLinkList as PageList;
  const { handleClickLinkedElement } = useClickLinkedElement(pageLinkList);

  const languageType = options && options['languageType'];
  const handleClick = () => {
    handleClickLinkedElement(link, isPreview, isEdit, languageType);
  };

  return (
    <div className={cx('content', align, { flex: flex })}>
      <button
        onClick={handleClick}
        className={cx('button', { cursor: hasLink }, { max_width: viewMode === VIEW_MODE.MOBILE })}
        style={{
          backgroundColor: `${backgroundColor}`,
          borderRadius: `${leftTopRadius}px ${rightTopRadius}px ${rightDownRadius}px ${leftDownRadius}px`,
          padding: `${sizeHeight}px ${sizeWidth}px`,
        }}
      >
        <span
          className={cx('text')}
          style={{
            color,
            whiteSpace: viewMode === VIEW_MODE.MOBILE ? 'normal' : 'nowrap',
          }}
        >
          {viewButton.value}
        </span>
      </button>
    </div>
  );
};

export default ViewButton;
