import Lottie from 'react-lottie';
import classnames from 'classnames/bind';
import style from './Loading.module.scss';
import animationData from './builder_loader.json';
import Portal from '../../common/portal/Portal';
import useLoading from 'ui/context/loading/useLoading';

const cx = classnames.bind(style);

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
};

const Loading = () => {
  const { loading } = useLoading();

  return loading ? <LoadingComponent /> : <></>;
};

export const LoadingComponent = () => {
  return (
    <Portal domId={'loading-root'}>
      <div className={cx('container')}>
        <div className={cx('loading')}>
          <Lottie options={defaultOptions} width={42} height={42} isClickToPauseDisabled={true} />
        </div>
      </div>
    </Portal>
  );
};

export default Loading;
