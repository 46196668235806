import classnames from 'classnames/bind';
import styles from './ViewBoardImage.module.scss';
import IViewContents from '../../../interface/contents/IViewContents';
import { IBoard, TBoardViewType } from '../../../interface/contents/board/IBoard';
import { VIEW_MODE, ViewModeType } from '../../../interface/common/ViewMode';
import { useState } from 'react';
import useViewBoardImage from './useViewBoardImage';
import Icon from '../../../common/icon/Icon';
import { COLORS } from '../../../constants/colors';
import { OFF } from '../../../constants/common';
import { IFile } from '../../../interface/common/common';
import { getImageURL } from 'ats-editor/src/utils/common/file.utils';
const cx = classnames.bind(styles);

export interface IProps {
  imageFile: IFile;
  viewBoard: IViewContents<IBoard>;
  viewMode: ViewModeType;
  viewType: TBoardViewType;
  previewTabType?: ViewModeType;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const ViewBoardImage = ({ imageFile, viewType, viewBoard, viewMode, previewTabType, containerRef }: IProps) => {
  const imagePartsInfo = useViewBoardImage({
    viewBoard,
    viewMode,
    viewType,
    previewTabType,
    containerRef,
  });
  const { imageSizeFixed, customBorderRadius } = imagePartsInfo;
  const [showDefaultImage, setShowDefaultImage] = useState(false);

  if (viewBoard.style.thumbnailImageStatus === OFF) return null;

  return (
    <>
      {!showDefaultImage && imageFile ? (
        <img
          onError={(e) => {
            console.error(e);
            setShowDefaultImage(true);
          }}
          className={cx('article-image')}
          src={getImageURL(imageFile) ?? ''}
          alt={`${imageFile.fileUid}`}
          style={{
            width: imageSizeFixed.width,
            height: imageSizeFixed.height,
            objectFit: 'cover',
            borderRadius: customBorderRadius,
          }}
        />
      ) : (
        <>
          <div
            className={cx('article-image', 'empty')}
            style={{
              width: imageSizeFixed.width,
              height: imageSizeFixed.height,
              borderRadius: customBorderRadius,
              flexShrink: 0,
            }}
          >
            <Icon name={'photo_filled'} size={viewMode === VIEW_MODE.MOBILE ? 40 : 64} color={COLORS.gray400} />
          </div>
        </>
      )}
    </>
  );
};

export default ViewBoardImage;
