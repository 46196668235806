import { OFF } from '../constants/common';
import { LINK_TYPE, LinkStyle } from '../interface/common/Link';
import { Nullable } from '../interface/common/common';
import { URL_TYPE } from '../interface/header/IMenu';

export const getValidCheckedLink = (link: Nullable<LinkStyle>): Nullable<LinkStyle> => {
  if (!link || link.linkStatusType === OFF) return null;

  switch (link.linkType) {
    case LINK_TYPE.PAGE:
      if (link.pageUrl?.urlType === URL_TYPE.INTERNAL) {
        return link.pageSn ? link : null;
      }
      return link.pageUrl.url ? link : null;
    case LINK_TYPE.URL:
      return link.url ? link : null;
  }
};

export const checkValidLink = (link: Nullable<LinkStyle>): boolean => {
  return !!getValidCheckedLink(link);
};
