import { Descendant } from 'slate';
import type { PopupGridAreaType, PopupTextSizeRecordType } from 'ui/interface/popup/popup.interface';

export const POPUP_LIST_MAX_LENGTH = 10;

export const POPUP_ANNOUNCEMENT_TYPE = {
  NORMAL: 'NORMAL',
  ALWAYS: 'ALWAYS',
} as const;

export const POPUP_TEXT_SIZE_TYPE = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
  CUSTOM: 'CUSTOM',
} as const;

export const POPUP_TEXT_SIZE_RECORD = {
  [POPUP_TEXT_SIZE_TYPE.SMALL]: {
    width: 320,
    height: 320,
  },
  [POPUP_TEXT_SIZE_TYPE.MEDIUM]: {
    width: 560,
    height: 400,
  },
  [POPUP_TEXT_SIZE_TYPE.LARGE]: {
    width: 720,
    height: 500,
  },
  [POPUP_TEXT_SIZE_TYPE.CUSTOM]: {
    width: 800,
    height: 550,
  },
} as const satisfies PopupTextSizeRecordType;

export const POPUP_TYPE = {
  IMAGE: 'IMAGE',
  TEXT: 'TEXT',
} as const;

export const POPUP_GRID = {
  ROW: 3,
  COL: 3,
} as const;

export const MAX_POPUP_IMAGE_WIDTH = 800;
export const MIN_POPUP_IMAGE_WIDTH = 200;
export const MAX_POPUP_IMAGE_HEIGHT = 600;
export const MIN_POPUP_IMAGE_HEIGHT = 200;

export const POPUP_REGISTER_TYPE = {
  UPDATE: 'UPDATE',
  POST: 'POST',
} as const;

export const GRID_POSITION_PROPS = {
  A0: ['top', 'left'],
  A1: ['top', 'left'],
  A2: ['top', 'right'],
  B0: ['top', 'left'],
  B1: ['top', 'left'],
  B2: ['top', 'right'],
  C1: ['bottom', 'left'],
  C0: ['bottom', 'left'],
  C2: ['bottom', 'right'],
} satisfies Record<PopupGridAreaType, string[]>;
export const POPUP_STACK_OFFSET = 24;

export const POPUP_DEFAULT_BACKGROUND_COLOR = '#F5F6F7';

export const POPUP_DEFAULT_TEXT = [
  {
    type: 'paragraph',
    align: 'center',
    children: [
      {
        text: 'JOBDA ATS',
        fontSize: 24,
        bold: true,
        color: '#343a40',
      },
    ],
  },
  {
    type: 'paragraph',
    align: 'center',
    children: [
      {
        fontSize: 24,
        bold: true,
        text: '상반기 공개채용 진행중',
        color: '#343a40',
      },
    ],
  },
  {
    type: 'paragraph',
    align: 'center',
    children: [
      {
        fontSize: 16,
        color: '#343a40',
        text: '',
      },
    ],
  },
  {
    type: 'paragraph',
    align: 'center',
    children: [
      {
        fontSize: 16,
        text: '뭘 좋아할지 몰라서 다 준비했어요.',
        color: '#343a40',
      },
    ],
  },
  {
    type: 'paragraph',
    align: 'center',
    children: [
      {
        fontSize: 16,
        text: 'JOBDA ATS 56개 전 직무 오픈',
        color: '#343a40',
      },
    ],
  },
  {
    type: 'paragraph',
    align: 'center',
    children: [
      {
        fontSize: 12,
        text: ' ',
      },
    ],
  },
  {
    type: 'paragraph',
    align: 'center',
    children: [
      {
        fontSize: 16,
        text: '기간: 2023 0월 00일 ~ 0월 00일',
        bold: true,
        color: '#12b886',
      },
    ],
  },
  {
    type: 'button',
    children: [
      {
        text: '      채용공고 바로가기      ',
      },
    ],
    size: 'M',
    style: {
      backgroundColor: '#12b886',
      borderRadius: 50,
      color: '#FFFFFF',
    },
    align: 'CENTER',
  },
  {
    type: 'paragraph',
    children: [
      {
        text: '',
      },
    ],
  },
] as Descendant[];
