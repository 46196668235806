import classnames from 'classnames/bind';
import style from './ViewMargin.module.scss';
import { VIEW_MODE, ViewModeType } from '../../../interface/common/ViewMode';
import IViewContents from '../../../interface/contents/IViewContents';
import { FC } from 'react';
import IMargin from '../../../interface/contents/margin/IMargin';

const cx = classnames.bind(style);

interface IViewMarginProps {
  viewMode?: ViewModeType;
  viewMargin: IViewContents<IMargin>;
  isPreview?: boolean;
}

const ViewMargin: FC<IViewMarginProps> = ({ viewMode, viewMargin, isPreview }) => {
  const {
    style: { size, mobileMarginStatus },
  } = viewMargin;

  if (viewMode === VIEW_MODE.MOBILE && mobileMarginStatus === 'OFF') return null;

  return <div className={cx('container')} style={{ height: `${size}px` }} />;
};

export default ViewMargin;
