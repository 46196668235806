import { LinkStyle, LINK_TYPE } from '../../interface/common/Link';
import { Nullable } from '../../interface/common/common';
import { URL_TYPE } from '../../interface/header/IMenu';
import { getValidCheckedLink } from '../../util/link.utils';
import useUrlRouter from '../useUrlRouter';
import { ReadonlyURLSearchParams, useSearchParams } from 'next/navigation';
import { PageList } from '../../interface/common/hasOption';
import { LANGUAGE, Language } from '../../constants/language';
import { useUrl } from '../useUrl';

const useClickLinkedElement = (pageLinkList: PageList) => {
  const { get } = useSearchParams() as ReadonlyURLSearchParams;
  const { handleUrlClick } = useUrlRouter();
  const { buildUrl, getSearchParam } = useUrl();

  const handleClickLinkedElement = (
    _link: Nullable<LinkStyle>,
    isPreview = false,
    isEdit = false,
    languageType: Language = LANGUAGE.KOR
  ) => {
    const link = getValidCheckedLink(_link);

    if (!link) return;

    switch (link.linkType) {
      case LINK_TYPE.PAGE:
        const {
          pageSn,
          pageUrl: { url, urlType },
        } = link;
        const internalUrl = pageLinkList?.find((page) => page.pageSn === pageSn)?.urlPath;

        const getPreviewUrl = () => {
          if (isEdit)
            return buildUrl('/editor/setting')
              .withSearchParam('designSn', getSearchParam('designSn'))
              .withSearchParam('pageSn', pageSn)
              .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
              .getResult();
          return buildUrl('/editor/preview')
            .withSearchParam('designSn', getSearchParam('designSn'))
            .withSearchParam('pageSn', pageSn)
            .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
            .getResult();
        };
        const getUrl = () => {
          if (isPreview) return getPreviewUrl();
          if (urlType === URL_TYPE.EXTERNAL) return url;
          if (urlType === URL_TYPE.INTERNAL)
            return buildUrl(`${internalUrl}`)
              .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
              .getResult();
        };
        return handleUrlClick({
          url: getUrl(),
          isExternal: urlType === URL_TYPE.EXTERNAL,
          isPreview,
        });
      case LINK_TYPE.URL:
        return handleUrlClick({ url: link.url, isExternal: true, isPreview });
      default:
        return;
    }
  };

  return { handleClickLinkedElement };
};

export default useClickLinkedElement;
