import { axios } from '@api/RequestClient';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import type { IPopupMutate } from 'ui/interface/popup/popup.interface';
import { AxiosError } from 'axios';

export interface IPopupResponse extends IPopupMutate {
  popUpSn: string;
}

interface IGetPopupV1Response {
  popUpList: IPopupResponse[];
}

export const getPopupV1 = async (): Promise<IGetPopupV1Response> => {
  const { data } = await axios.get('/pop-up/v1');
  return data;
};

export const useQueryGetPopup = (): UseQueryResult<IGetPopupV1Response, AxiosError> =>
  useQuery({
    queryKey: ['popup'],
    queryFn: getPopupV1,
  });
