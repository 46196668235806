'use client';

import { FC, createContext, ReactNode, useState } from 'react';
import { TPostThumbnailPostList } from '../../component/contents/board/useViewBoard';
import { IPostThumbnailPostListV1Response } from '../../interface/contents/board/getThumbnailPostListV1';

interface IBoardInjectedContextValueProps {
  children: ReactNode;
}

interface IBoardInjectedContextValue {
  mutatePostThumbnailPostListData: IPostThumbnailPostListV1Response;
  postThumbnailPostList: TPostThumbnailPostList;
  setMutatePostThumbnailPostListData: (value: IPostThumbnailPostListV1Response) => void;
  setPostThumbnailPostList: (value: TPostThumbnailPostList) => void;
}

const initialMutatePostThumbnailPostListData: IPostThumbnailPostListV1Response = {
  pagination: {
    size: 12,
    page: 1,
  },
  list: [],
};

export const initialBoardInjectedContextValue: IBoardInjectedContextValue = {
  mutatePostThumbnailPostListData: initialMutatePostThumbnailPostListData,
  postThumbnailPostList: () => {},
  setMutatePostThumbnailPostListData: () => {},
  setPostThumbnailPostList: () => {},
};
export const INITIAL_VIEW_BOARD_PROPS = initialBoardInjectedContextValue;

const BoardInjectedContext = createContext(initialBoardInjectedContextValue);

export const BoardInjectedContextProvider: FC<IBoardInjectedContextValueProps> = (props) => {
  const [postThumbnailPostList, setPostThumbnailPostList] = useState<TPostThumbnailPostList>(() => {});
  const [mutatePostThumbnailPostListData, setMutatePostThumbnailPostListData] =
    useState<IPostThumbnailPostListV1Response>(initialMutatePostThumbnailPostListData);
  const { children } = props;

  return (
    <BoardInjectedContext.Provider
      value={{
        mutatePostThumbnailPostListData,
        postThumbnailPostList,
        setMutatePostThumbnailPostListData,
        setPostThumbnailPostList,
      }}
    >
      {children}
    </BoardInjectedContext.Provider>
  );
};

export default BoardInjectedContext;
