import SlateEditor from 'ui/common/textEditor/SlateEditor';
import { POPUP_TEXT_SIZE_RECORD, POPUP_TEXT_SIZE_TYPE } from 'ui/constants/popup/popup.constant';
import type { PopupTextSizeTypeType } from 'ui/interface/popup/popup.interface';
import { useMemo } from 'react';
import style from './PopupText.module.scss';
import classnames from 'classnames/bind';
import { HasOption } from '../../interface/common/hasOption';

const cx = classnames.bind(style);

export interface IPositionStyle {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  zIndex?: number;
}
export interface ISizeSize {
  width: number | string;
  height: number | string;
}

export interface IPopupText {
  text?: string;
  sizeType?: PopupTextSizeTypeType;
  width?: number;
  height?: number;
  backgroundColor?: string;
  positionStyle?: IPositionStyle;
  isMobile?: boolean;
}
const PopupText = (props: IPopupText & HasOption) => {
  const { text: _text, sizeType, width, height, backgroundColor, positionStyle, isMobile = false, options } = props;
  const text = useMemo(() => JSON.parse(_text!), [_text]);

  const getStyle = (): ISizeSize => {
    if (isMobile) return { width: '100%', height: '100%' };
    if (sizeType === POPUP_TEXT_SIZE_TYPE.CUSTOM) return { width: width!, height: height! };
    return POPUP_TEXT_SIZE_RECORD[sizeType!];
  };

  return (
    <div className={cx('container')} style={getStyle()}>
      <div
        className={cx('popup-main', isMobile && 'isMobile')}
        style={{ ...positionStyle, backgroundColor: backgroundColor }}
      >
        <SlateEditor
          value={text}
          backgroundColor={backgroundColor}
          onChange={() => {}}
          editorClassName={cx('editor')}
          options={options}
          disabled
        />
      </div>
    </div>
  );
};

export default PopupText;
