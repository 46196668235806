import { IViewRecruitmentProps } from 'ui/component/contents/recruitment/useViewRecruitment';
import useRecruitInjectedContext from './hooks/useRecruitInjectedContext';
import { IViewBoardProps } from '../contents/board/useViewBoard';
import useBoardInjectedContext from './hooks/useBoardInjectedContext';

export interface IViewPageProps {
  viewRecruitmentProps: IViewRecruitmentProps;
  viewBoardProps: IViewBoardProps;
}

const useViewPage = (viewPageProps: IViewPageProps | undefined) => {
  useRecruitInjectedContext(viewPageProps?.viewRecruitmentProps);
  useBoardInjectedContext(viewPageProps?.viewBoardProps);
};

export default useViewPage;
