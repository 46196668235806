import Image from 'next/image';

import classnames from 'classnames/bind';
import styles from './ViewVideo.module.scss';
const cx = classnames.bind(styles);

const YoutubePlayButton = () => {
  return (
    <Image
      className={cx('youtube-logo')}
      width={72}
      height={50}
      src={'/img/editor/youtube_logo.png'}
      alt={'youtube-logo'}
    />
  );
};

export default YoutubePlayButton;
