import classNames from 'classnames/bind';
import { ViewModeType, VIEW_MODE } from '../../../interface/common/ViewMode';
import { IDivider } from '../../../interface/contents/divider/IDivider';
import IViewContents from '../../../interface/contents/IViewContents';
import style from './ViewDivider.module.scss';

interface IProps {
  viewMode?: ViewModeType;
  viewDivider: IViewContents<IDivider>;
}
const cx = classNames.bind(style);
const ViewDivider = ({ viewMode, viewDivider }: IProps) => {
  const { marginSize, color, mobileViewStatus } = viewDivider.style;

  if (viewMode === VIEW_MODE.MOBILE && mobileViewStatus === 'OFF') return null;

  return (
    <div className={cx('container')}>
      <div style={{ height: `${marginSize}px` }}></div>
      <div className={cx('div')} style={{ backgroundColor: color }}></div>
      <div style={{ height: `${marginSize}px` }}></div>
    </div>
  );
};

export default ViewDivider;
