import { FC, ReactNode } from 'react';
import { RecruitSettingContextProvider } from './RecruitSettingContext';
import { RecruitSettingInjectedContextProvider } from './RecruitSettingInjectedContext';
import { RecruitListInjectedContextProvider } from './RecruitListInjectedContext';

const RecruitProvider: FC<{ children: ReactNode }> = (props) => {
  return (
    <RecruitListInjectedContextProvider>
      <RecruitSettingInjectedContextProvider>
        <RecruitSettingContextProvider>{props.children}</RecruitSettingContextProvider>
      </RecruitSettingInjectedContextProvider>
    </RecruitListInjectedContextProvider>
  );
};

export default RecruitProvider;
