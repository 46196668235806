import IBoardStyle from './IBoardStyle';
import IBoardValue from './IBoardValue';

export const BOARD_VIEW_TYPE = {
  LIST: 'LIST',
  KANBAN: 'KANBAN',
} as const;

export type TBoardViewType = keyof typeof BOARD_VIEW_TYPE;

export const boardViewTypeTextMap: Record<TBoardViewType, string> = {
  [BOARD_VIEW_TYPE.LIST]: '리스트',
  [BOARD_VIEW_TYPE.KANBAN]: '칸반',
};

export interface IBoard {
  style: IBoardStyle;
  value: IBoardValue;
}
