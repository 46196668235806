import { FC, ReactNode } from 'react';
import RecruitProvider from './recruiter/RecruitContext';
import BoardProvider from './board/BoardContext';

const ContentsProvider: FC<{ children: ReactNode }> = (props) => {
  return (
    <BoardProvider>
      <RecruitProvider>{props.children}</RecruitProvider>
    </BoardProvider>
  );
};

export default ContentsProvider;
