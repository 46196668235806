import { useCallback, useEffect, useMemo, useState } from 'react';
import IViewContents from '../../../interface/contents/IViewContents';
import { BOARD_VIEW_TYPE, IBoard, TBoardViewType } from '../../../interface/contents/board/IBoard';
import { VIEW_MODE, ViewModeType } from '../../../interface/common/ViewMode';

interface IProps {
  viewBoard: IViewContents<IBoard>;
  viewMode: ViewModeType;
  viewType: TBoardViewType;
  previewTabType?: ViewModeType;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const useViewBoardImage = ({ viewBoard, viewMode, viewType, previewTabType, containerRef }: IProps) => {
  const {
    // style: { thumbnailImageStatus, thumbnailImageSizePercentage, borderRadius, alignType },
    style: { borderRadius },
  } = viewBoard;
  const customBorderRadius = useMemo(() => {
    if (!borderRadius) return 0;

    const { leftTopRadius, rightTopRadius, rightDownRadius, leftDownRadius } = borderRadius;
    return `${leftTopRadius}px ${rightTopRadius}px ${rightDownRadius}px ${leftDownRadius}px`;
  }, [borderRadius]);

  const [containerWidth, setContainerWidth] = useState(0);

  const setWidth = useCallback(() => {
    if (!containerRef?.current?.offsetWidth) return;
    setContainerWidth(containerRef.current.offsetWidth);
  }, [containerRef]);

  useEffect(() => {
    setWidth();
    window.addEventListener('resize', setWidth);
    return () => window.removeEventListener('resize', setWidth);
  }, [setWidth]);

  const imageSizeFixed = useMemo(() => {
    switch (viewType) {
      case BOARD_VIEW_TYPE.LIST:
        switch (viewMode) {
          case VIEW_MODE.MOBILE:
            if (previewTabType === VIEW_MODE.MOBILE) {
              return { width: '124px', height: '124px' };
            }
            return {
              width: `${Math.max(124, Math.min(178, 178 - Math.round((54 / 408) * (768 - containerWidth))))}px`,
              height: '124px',
            };
          default:
            return { width: '330px', height: '230px' };
        }
      case BOARD_VIEW_TYPE.KANBAN:
        switch (viewMode) {
          case VIEW_MODE.MOBILE:
            return { width: '100%', height: '120px' };
          case VIEW_MODE.TABLET:
            return { width: '100%', height: '240px' };
          default:
            return { width: '100%', height: '254px' };
        }
    }
  }, [previewTabType, containerWidth, viewMode, viewType]);

  // 이미지 사이즈 및 정렬 기능 스펙아웃에 따른 주석 처리
  // const showThumbnailImage = useMemo(() => {
  //   return thumbnailImageStatus === ON && thumbnailImageSizePercentage > 0;
  // }, [thumbnailImageStatus, thumbnailImageSizePercentage]);

  // const thumbnailImageSizeRatio = useMemo(() => {
  //   return thumbnailImageSizePercentage / 100;
  // }, [thumbnailImageSizePercentage]);

  // return { showThumbnailImage, imageSizeFixed, customBorderRadius, thumbnailImageSizeRatio };
  return { imageSizeFixed, customBorderRadius };
};

export default useViewBoardImage;
