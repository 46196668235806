'use client';

import { FC, createContext, ReactNode, useState } from 'react';
import { IGetSettingV1Response } from 'ui/interface/position/getSettingV1';
import { IGetClassificationTagV1Response } from '../../interface/position/getClassificationTagV1';
import { TGetClassificationTag, TGetSetting } from '../../component/contents/recruitment/useViewRecruitment';

interface IRecruitSettingInjectedContextValueProps {
  children: ReactNode;
}

interface IRecruitSettingInjectedContextValue {
  queryGetSettingData: IGetSettingV1Response;
  getSetting: TGetSetting;
  queryGetClassificationTagData: IGetClassificationTagV1Response;
  getClassificationTag: TGetClassificationTag;

  setQueryGetSettingData: (value: IGetSettingV1Response) => void;
  setGetSetting: (value: TGetSetting) => void;
  setQueryGetClassificationTagData: (value: IGetClassificationTagV1Response) => void;
  setGetClassificationTag: (value: TGetClassificationTag) => void;
}

const initialQueryGetSettingData: IGetSettingV1Response = {
  sortType: 'CREATED_DATE_TIME',
  tagType: 'USER_PROVIDED',
  tagTypeList: [],
  filterTypeList: [],
  careerTypeList: [],
  jobGroupList: [],
  workingAreaList: [],
  submissionStatusList: [],
};

const initialRecruitSettingInjectedContextValue: IRecruitSettingInjectedContextValue = {
  queryGetSettingData: initialQueryGetSettingData,
  getSetting: () => {},
  queryGetClassificationTagData: {
    tagTypeList: [],
  },
  getClassificationTag: () => {},
  setQueryGetSettingData: () => {},
  setQueryGetClassificationTagData: () => {},
  setGetSetting: () => {},
  setGetClassificationTag: () => {},
};

const RecruitSettingInjectedContext = createContext(initialRecruitSettingInjectedContextValue);

export const RecruitSettingInjectedContextProvider: FC<IRecruitSettingInjectedContextValueProps> = (props) => {
  const [getSetting, setGetSetting] = useState<TGetSetting>(() => {});
  const [queryGetSettingData, setQueryGetSettingData] = useState<IGetSettingV1Response>(initialQueryGetSettingData);
  const [getClassificationTag, setGetClassificationTag] = useState<TGetClassificationTag>(() => {});
  const [queryGetClassificationTagData, setQueryGetClassificationTagData] = useState<IGetClassificationTagV1Response>({
    tagTypeList: [],
  });
  const { children } = props;

  return (
    <RecruitSettingInjectedContext.Provider
      value={{
        queryGetSettingData,
        getSetting,
        queryGetClassificationTagData,
        getClassificationTag,

        setGetSetting,
        setQueryGetSettingData,
        setGetClassificationTag,
        setQueryGetClassificationTagData,
      }}
    >
      {children}
    </RecruitSettingInjectedContext.Provider>
  );
};

export default RecruitSettingInjectedContext;
